import React from "react";
import "../../css/work/project-flex-list.css";

const ProjectFlexList = () => {
  return (
    <div className="project-flex-list">
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_1.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_2.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_3.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_4.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_5.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_6.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_7.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_8.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_9.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_10.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_11.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_12.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_13.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/project_flex_14.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project desktop">
        <video autoPlay loop muted playsInline>
          <source
            src={`/videos/project_flex_desktop_15.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project desktop">
        <img src="/images/project_flex_desktop_16.png" alt="" />
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline className="edit">
          <source src={`/videos/project_flex_17.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project desktop">
        <video autoPlay loop muted playsInline>
          <source
            src={`/videos/project_flex_desktop_18.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project desktop">
        <video autoPlay loop muted playsInline>
          <source
            src={`/videos/project_flex_desktop_19.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project desktop">
        <img src="/images/project_flex_desktop_20.png" alt="" />
      </div>
      <div className="project desktop ">
        <video autoPlay loop muted playsInline className="edit">
          <source
            src={`/videos/project_flex_desktop_21.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project">
        <video autoPlay loop muted playsInline className="edit">
          <source src={`/videos/project_flex_22.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project desktop">
        <img src="/images/project_flex_desktop_23.png" alt="" />
      </div>
      <div className="project desktop">
        <video autoPlay loop muted playsInline>
          <source
            src={`/videos/project_flex_desktop_24.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project desktop">
        <video autoPlay loop muted playsInline>
          <source
            src={`/videos/project_flex_desktop_25.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="project desktop">
        <img src="/images/project_flex_desktop_26.png" alt="" />
      </div>
    </div>
  );
};

export default ProjectFlexList;

import React, { useEffect, useState } from "react";
import "../css/contact-modal.css";
import BtnMagnetic from "./ButtonMagnetic";
import ContactProceedBtn from "./ContactProceedBtn";

const ContactModal = ({
  contactBool,
  handleNext,
  setContactBool,
  individualDetails,
  setIndividualDetails,
}) => {
  const [whatsApp, setWhatsApp] = useState(false);
  const [emailBool, setEmailBool] = useState(false);
  const [errorBool, setErrorBool] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [whatSappText, setWhatSappText] = useState("");

  useEffect(() => {
    if (!contactBool) {
      setWhatsApp(false);
      setEmailBool(false);
    }
  }, [contactBool]);

  useEffect(() => {
    const digitsOnly = whatSappText.slice(1);

    if (whatSappText.split("")[0] !== "+") {
      setErrorMessage("Start with a valid country code (e.g., +234, +44, +1).");
    } else if (!/^\d+$/.test(digitsOnly)) {
      setErrorMessage(
        "Number must contain only digits after the country code."
      );
    } else if (digitsOnly.length < 9 || digitsOnly.length > 15) {
      setErrorMessage("Number must be 9-15 digits long.");
    } else setErrorMessage("Valid WhatsApp number");
  }, [whatSappText]);

  const onSubmit = (e) => {
    setErrorBool(false);
    e.preventDefault();

    if (whatsApp && errorMessage !== "Valid WhatsApp number") {
      setErrorBool(true);
    } else {
      setErrorBool(false);
      setContactBool(false);
      handleNext();
    }
  };

  return (
    <div className={`contact-modal ${contactBool && "review"}`}>
      <div className="icon-wrap" onClick={() => setContactBool(false)}>
        <img src="/svgs/collapse.svg" alt="" />
      </div>
      <div className="contact-title">How can we contact you</div>
      {!whatsApp && !emailBool ? (
        <>
          <div className="modes">
            <div className="mode" onClick={() => setWhatsApp(true)}>
              <img src="/svgs/WA_Logo.svg" alt="" />
              <div className="social-text">WhatsApp</div>
            </div>
            <div className="mode" onClick={() => setEmailBool(true)}>
              <img src="/svgs/envelopes.svg" alt="" />
              <div className="social-text">E-mail</div>
            </div>
          </div>
          <BtnMagnetic>
            <ContactProceedBtn
              text="Proceed"
              socials={!whatsApp && !emailBool}
            />
          </BtnMagnetic>
        </>
      ) : (
        <form className="form" onSubmit={onSubmit}>
          <div
            className="form-mode"
            onClick={() => {
              setWhatsApp(false);
              setEmailBool(false);
              setIndividualDetails({
                ...individualDetails,
                contactDetails: "",
              });
              setErrorBool(false);
            }}
          >
            <img
              src={whatsApp ? "/svgs/WA_Logo.svg" : "/svgs/envelopes.svg"}
              alt=""
            />
            <div className="social-text">
              {whatsApp ? "WhatsApp" : "E-mail"}
            </div>
          </div>

          <h3>
            {whatsApp ? "What's your WhatsApp Number?" : "What's your email?"}
          </h3>
          <input
            className={`${errorBool && "input-error"}`}
            type={whatsApp ? "tel" : "email"}
            value={individualDetails.contactDetails}
            onChange={(e) => {
              setWhatSappText(e.target.value);
              setIndividualDetails({
                ...individualDetails,
                contactDetails: e.target.value,
              });
              setErrorBool(false);
            }}
            placeholder={
              whatsApp
                ? "Your WhatsApp Number, include country code e.g +234, +44"
                : "help@marcus.com"
            }
            required
          />
          {errorBool && <div className="error">{errorMessage}</div>}
          <BtnMagnetic>
            <ContactProceedBtn text="Proceed" />
          </BtnMagnetic>
        </form>
      )}
    </div>
  );
};

export default ContactModal;

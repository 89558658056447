import React, { forwardRef } from "react";
import "../css/burger-menu.css";
import Framer from "./CursorMagnetic";

const BurgerMenu = forwardRef(function index(props, ref) {
  return (
    <Framer>
      <div
        className={`burger-menu-icon burger ${
          (props.currentIndex === 2 ||
            props.currentIndex === 5 ||
            props.currentIndex === 6 ||
            props.currentIndex === 0) &&
          "burger-dark"
        } `}
        onClick={() => props.setMenuBool(!props.menuBool)}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bounds" ref={ref}></div>
      </div>
    </Framer>
  );
});

export default BurgerMenu;

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "../css/about/about.css";
import {
  useScroll,
  useTransform,
  motion,
  AnimatePresence,
} from "framer-motion";
import StickyCursor from "../components/StickyCursor";
import { debounce } from "lodash";
import Footer from "../components/about components/Footer";
import PageTransition from "../components/PageTransition";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import MenuContainer from "../components/MenuContainer";
import BurgerMenu from "../components/BurgerMenu";
import { useParams } from "react-router-dom";
import NextCase from "../components/work project components/NextCase";
import StickyCase from "../components/work project components/StickyCase";
import WorkMobileProjectPage from "./WorkMobileProjectPage";
import SafulPay from "../components/work project components/SafulPay";
import Immunocore from "../components/work project components/Immunocore";
import Endo from "../components/work project components/Endo";
import RequestButton from "../components/work project components/RequestButton";
import Trigger from "../components/work project components/Trigger";
import Ateni from "../components/work project components/Ateni";
import LendSqr from "../components/work project components/Lendsqr";
import GetLinked from "../components/work project components/GetLinked";

const RequestButtonPage = ({ setIsHomeLink }) => {
  const targetRef = useRef(null);
  const cardsContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const stickyElement = useRef(null);
  const [menuBool, setMenuBool] = useState(false);
  const [project, setProject] = useState({});
  const [nextProject, setNextProject] = useState({});
  const [projectPage, setProjectPage] = useState({
    title: "safulpay",
    project: <SafulPay />,
  });
  const projectPages = [
    {
      title: "safulpay",
      project: <SafulPay />,
    },
    {
      title: "immunocore",
      project: <Immunocore />,
    },
    {
      title: "endo",
      project: <Endo />,
    },
    {
      title: "requestbutton",
      project: <RequestButton />,
    },
    {
      title: "trigger",
      project: <Trigger />,
    },
    {
      title: "ateni",
      project: <Ateni />,
    },
    {
      title: "lendsqr",
      project: <LendSqr />,
    },
    {
      title: "getlinked",
      project: <GetLinked />,
    },
  ];

  const projects = [
    {
      title: "SafulPay",
      src: "/images/safulpay.jpg",
      color: "#eeff00",
      background: "rgba(250, 255, 188, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(231, 255, 133, 0.64)",
      link: "/work/safulpay",
      details:
        "SafulPay is a mobile app that allows users to securely pay for goods and services. It utilizes the NFC technology to read and write cards, making it easy for customers to pay.",
      category: "Branding & Design",
      year: 2024,
      location: "Sierra Leone",
      longImage: "/images/safulpay_longimage.png",
      phoneScreenshots: [
        "/images/safulpay_potrait_1.png",
        "/images/safulpay_potrait_2.png",
      ],
      desktop: "safulpay_desktop",
    },
    {
      title: "Immunocore",
      src: "/images/immunocore.png",
      color: "#00ffb3",
      background: "rgba(128, 255, 211, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 179, 0.64)",
      category: "Development",
      link: "/work/immunocore",
      details:
        "Immunocore is a commercial-stage biotechnology company that discovered, developed and commercialized the world’s first approved T cell receptor (TCR) therapy.",
      location: "United States",
      year: 2024,
      desktop: "immunocore_desktop",
      phoneVideo: "immunocore_phone_video",
    },
    {
      title: "Endo",
      src: "/images/endo.png",
      color: "#ff00ee",
      background: "rgba(255, 51, 238, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 0, 238, 0.64)",
      category: "Development",
      link: "/work/endo",
      details:
        "Endo empowers individuals and communities alike, fostering better health outcomes and a brighter future for all.",
      location: "United States",
      year: 2024,
      desktop: "endo_desktop",
      phoneVideo: "endo_phone_video",
    },
    {
      title: "RequestButton",
      src: "/images/request.png",
      color: "#00ff2f",
      background: "rgba(128, 255, 79, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 47, 0.64)",
      owned: true,
      link: "/work/requestbutton",
      details:
        "Request is a smart, user-friendly feature that allows diners to quickly make requests at restaurants without waiting for staff.",
      location: "Global",
      year: 2023,
      category: "Design & Develpment",
      ownedImg: "/images/requestbutton_owned.png",
    },
    {
      title: "Trigger",
      src: "/images/trigger.png",
      color: "#ff5733",
      background: "rgba(255, 99, 51, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 53, 33, 0.64)",
      owned: true,
      link: "/work/trigger",
      details:
        "Trigger helps users to access their medical journey with ease, from E-Bill statements to appointment tracking.",
      location: "Global",
      year: 2023,
      category: "Design",
      ownedImg: "/images/trigger_owned.png",
    },
    {
      title: "Ateni",
      src: "/images/ateni.png",
      color: "#007bff",
      background: "rgba(51, 153, 255, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 123, 255, 0.64)",
      link: "/work/ateni",
      details:
        "Ateni is a premier residential construction company dedicated to turning dreams into reality.",
      location: "United Kingdom",
      year: 2023,
      category: "Branding & Design",
      longImage: "/images/ateni_longimage.png",
      desktop: "ateni_desktop",
      phoneScreenshots: [
        "/images/ateni_potrait_1.png",
        "/images/ateni_potrait_2.png",
      ],
    },
    {
      title: "Lendsqr",
      src: "/images/lendsqr.png",
      color: "#ffcc00",
      background: "rgba(255, 204, 51, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 204, 0, 0.64)",
      link: "/work/lendsqr",
      details:
        "Lendsqr's loan management software makes lending easy and profitable, helping you start, launch, and scale your loan business across multiple channels with smart, cost-effective solutions.",
      location: "Nigeria",
      year: 2023,
      category: "Development",
      desktop: "lendsqr_desktop",
      phoneVideo: "lendsqr_phone_video",
    },
    {
      title: "GetLinked",
      src: "/images/getlinked.png",
      color: "#8A2BE2",
      background: "rgba(138, 43, 226, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(75, 0, 130, 0.64)",
      link: "/work/getlinked",
      details: "GetLinked is a design, development and concept hackathon. ",
      location: "Nigeria",
      year: 2023,
      category: "Development",
    },
  ];

  useEffect(() => {
    const projectData = projectPages.find(
      (p) => p.title.toLowerCase() === "requestbutton"
    );
    setProjectPage(projectData);
  }, [projectPages]);

  useEffect(() => {
    const projectData = projects.find(
      (p) => p.title.toLowerCase() === "requestbutton"
    );
    setProject(projectData);

    const currentIndex = projects.findIndex(
      (p) => p.title.toLowerCase() === "requestbutton"
    );
    const nextIndex = currentIndex + 1;
    console.log(currentIndex, nextIndex);
    setNextProject(projects[nextIndex]);
  }, [projects]);

  // Function to handle the calculation of width and scroll height
  const calculateDimensions = () => {
    if (cardsContainerRef.current && targetRef.current) {
      const cardsWidth = cardsContainerRef.current.scrollWidth;
      const viewportWidth = targetRef.current.offsetWidth;

      setContainerWidth(cardsWidth);
      setViewportWidth(viewportWidth);

      // Dynamically calculate the height based on content width
      const totalScrollHeight =
        (cardsWidth / viewportWidth) * window.innerHeight;
      setScrollHeight(totalScrollHeight);
    }
  };

  useLayoutEffect(() => {
    // Perform the initial calculation
    calculateDimensions();

    // Add a resize event listener to recalculate on window resize
    const handleResize = debounce(() => {
      calculateDimensions();
    }, 300); // 300ms debounce to prevent excessive function calls

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start start", "end end"],
  });

  const x = useTransform(
    scrollYProgress,
    [0, 1],
    [0, -(containerWidth - viewportWidth)] // Stop at the last card
  );

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Set initial mobile state based on screen width
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 575); // Adjust threshold as per mobile device width (e.g. 768px)
    };

    // Add event listener for screen resizing
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile(); // Initial check when component mounts

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  const [isCase, setIsCase] = useState(false);

  return (
    <>
      {!isMobile ? (
        <div
          className="about-page"
          ref={targetRef}
          style={{ scrollBehavior: "smooth" }}
        >
          <Helmet>
            <title>
              Blueprint - Work | RequestButton - Innovative Digital Solution
            </title>
          </Helmet>
          <div className="dead" ref={stickyElement}></div>
          <Navbar ref={stickyElement} currentIndex={2} />
          <AnimatePresence mode="wait">
            {menuBool && (
              <MenuContainer error={true} setMenuBool={setMenuBool} />
            )}
          </AnimatePresence>
          <BurgerMenu
            ref={stickyElement}
            menuBool={menuBool}
            setMenuBool={setMenuBool}
            currentIndex={2}
          />

          <div
            className="h-screen sticky top-0 flex items-center overflow-hidden about-container"
            style={{
              background: `url("/svgs/home_background_black.svg")`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <img
              src="/svgs/home_background_black.svg"
              alt=""
              className="work-bg"
            />

            <motion.div
              ref={cardsContainerRef}
              style={{ x }}
              className="flex fixed top-0"
            >
              {/* <ProjectContent {...project} projects={projects} />
              <ProjectContent {...project} projects={projects} />
              <ProjectContent {...project} projects={projects} /> */}
              <RequestButton />

              {/* <AboutWeCanHelp />
              <AboutWeCanHelp />
              <AboutWeCanHelp />
              <NextCase setIsCase={setIsCase} {...nextProject} />
              <NextCase setIsCase={setIsCase} {...nextProject} />
              <NextCase setIsCase={setIsCase} {...nextProject} /> */}
              <NextCase setIsCase={setIsCase} {...nextProject} />
              <Footer setIsHomeLink={setIsHomeLink} />
            </motion.div>
          </div>

          <StickyCursor currentIndex={2} stickyElement={stickyElement} />
          <StickyCase isCase={isCase} />
          <PageTransition pageTitle={"requestbutton"} />
        </div>
      ) : (
        <WorkMobileProjectPage setIsHomeLink={setIsHomeLink} />
      )}
    </>
  );
};

export default RequestButtonPage;

import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../css/navbar.css";
import BtnMagnetic from "./ButtonMagnetic";

// Rename the function to a proper React component name (starting with an uppercase letter)
const Navbar = ({
  currentIndex,
  navMobileThanks,
  handlePrev,
  setMenuRepoBool,
  setIsHomeLink,
  contact,
  setCurrentIndex,
  workMb,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={`navbar ${
        (currentIndex === 2 ||
          currentIndex === 5 ||
          currentIndex === 6 ||
          currentIndex === 0) &&
        "dark-nav"
      } ${workMb && "nav-tablet"}`}
    >
      <Link to="/about-us" className="logo">
        <BtnMagnetic>
          <img src="/svgs/Bp_logo_dark.svg" alt="" />
        </BtnMagnetic>
        <div className="logo-txt">About Us</div>
      </Link>

      <div
        className={`nav-mobile ${
          currentIndex > 0 && !navMobileThanks && "change"
        }  ${
          (currentIndex === 1 || currentIndex === 3 || currentIndex === 4) &&
          "nav-mobile-dark"
        }`}
      >
        {currentIndex > 0 && !navMobileThanks && (
          <div
            className="icon-wrap icon-btn"
            onClick={() => (contact ? navigate(-1) : handlePrev())}
          >
            <img src="/svgs/angle-left.svg" alt="" />
          </div>
        )}
        <Link
          to="/"
          onClick={() => {
            setIsHomeLink(true);

            if (location.pathname === "/") {
              setCurrentIndex(0);
            }
          }}
          className="icon-wrap"
        >
          <img src="/svgs/logo_mobile.svg" className="logo_img" alt="" />
        </Link>
        <div
          className={`icon-wrap icon-burger ${
            currentIndex > 0 && !navMobileThanks && "icon-burger-mb"
          }`}
          onClick={() => {
            setMenuRepoBool(true);
          }}
        >
          <img src="/svgs/line_burger.svg" alt="" />
        </div>
      </div>

      {/* <img ref={ref} src="/svgs/burger.svg" alt="" className="burger" /> */}
      {/* <BurgerMenu ref={ref} /> */}

      <div className="text">
        Blueprint is <br /> the Future <br />
        of Design <br /> and <br /> Innovation
      </div>
    </div>
  );
};

export default Navbar;

import React from "react";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import "../css/rounded-btn.css";
import BtnMagnetic from "./ButtonMagnetic";
import GrabMagnetic from "./GrabMagnetic";

export default function RoundedButton({
  children,
  backgroundColor = "#be4110",
  height = 300,
  width = 300,
  handleNext,
  submit,
  start,
  ...attributes
}) {
  const circle = useRef(null);
  let timeline = useRef(null);
  let timeoutId = null;
  useEffect(() => {
    timeline.current = gsap.timeline({ paused: true });
    timeline.current
      .to(
        circle.current,
        { top: "-25%", width: "150%", duration: 0.5, ease: "power2.in" },
        "enter"
      )
      .to(
        circle.current,
        { top: "-150%", width: "125%", duration: 0.45 },
        "exit"
      );
  }, []);

  const manageMouseEnter = () => {
    if (timeoutId) clearTimeout(timeoutId);
    timeline.current.tweenFromTo("enter", "exit");
  };

  const manageMouseLeave = () => {
    timeoutId = setTimeout(() => {
      timeline.current.play();
    }, 300);
  };

  return (
    <GrabMagnetic>
      <button
        type={submit ? "submit" : "button"}
        className={`roundedButton ${start && "start"}`}
        style={{ overflow: "hidden", height, width }}
        onClick={handleNext}
        onMouseEnter={() => {
          manageMouseEnter();
        }}
        onMouseLeave={() => {
          manageMouseLeave();
        }}
        {...attributes}
      >
        {children}
        <div ref={circle} style={{ backgroundColor }} className="circle"></div>
      </button>
    </GrabMagnetic>
  );
}

import React from "react";

const AboutMobileLanding = ({ individualDetails }) => {
  function capitalizeFirstLetter(word) {
    if (!word) return ""; // Handle cases where the word is empty or null
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  return (
    <div className="about-mobile-landing">
      <div className="welcome-content">
        <p>
          {individualDetails.name
            ? `Welcome to Blueprint, ${capitalizeFirstLetter(
                individualDetails.name
              )}!`
            : "Welcome to Blueprint!"}{" "}
          <br />
          <br /> We’re thrilled to partner with you on your journey to success.
          Here’s a closer look at what we do and why so many trust us with their
          most important projects.
        </p>
      </div>
      <h1>FROM YOUR IDEAS TO BLUEPRINT ING INTO REALITY</h1>
      <div className="outro">
        <p>
          At Blueprint, we believe that great design is the foundation of every
          successful product.
          <br />
          <br /> Our team of expert designers and developers collaborates
          closely with you to understand your vision and turn it into reality.
          Whether you’re a startup with a bold new idea or an SME looking to
          refine your existing offerings, Blueprint is here to help.
        </p>
      </div>
    </div>
  );
};

export default AboutMobileLanding;

import React from "react";
import { Link } from "react-router-dom";
import "../../css/about/about-nav.css";

const AboutNavBar = ({ setIsHomeLink }) => {
  return (
    <div className="about-navbar">
      <Link className="logo">
        <div className="scope">
          <p>BluePrint</p>
        </div>
        <img src="/svgs/blueprint_new.svg" alt="" />
      </Link>

      <div className="links">
        <div className="scope">
          <Link to="/projects">Projects</Link>
        </div>
        <div className="scope get">
          <Link to="/" onClick={() => setIsHomeLink(true)}>
            Get Started
          </Link>
        </div>
        <div className="scope">
          <Link to="/contact-us">Contact Us</Link>
        </div>
        <div className="scope">
          <Link to="/about-us" className="active">
            About Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutNavBar;

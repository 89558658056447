import React from "react";
import "../../css/work/project.content.css";

const RequestButton = () => {
  return (
    <div className="project-content" style={{ width: 2150 }}>
      <div className="screen-landing">
        <div className="screen-landing-title">RequestButton</div>
        <div className="owned">
          Privately Owned & <br /> Under Development
        </div>
        <div className="section-container">
          <div className="service-work">
            <p>ROLE/SERVICE</p>
            <h2>Design & Development</h2>
          </div>
          <div className="location-work">
            <p>LOCATION</p>
            <h2>Nigeria</h2>
          </div>
        </div>
      </div>
      <div className="owned-img-container" style={{ width: 1512 }}>
        <img
          src="/images/requestbutton_owned.png"
          alt=""
          className="owned-img"
        />
      </div>
    </div>
  );
};

export default RequestButton;

import { useTransform, motion, useScroll } from "framer-motion";
import { useRef } from "react";
import { Link } from "react-router-dom";

const CardsMobile = ({
  i,
  title,
  src,
  background,
  progress,
  range,
  location,
  details,
  targetScale,
  link,
}) => {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start end", "start start"],
  });

  const imageScale = useTransform(scrollYProgress, [0, 1], [2, 1]);
  const scale = useTransform(progress, range, [1, targetScale]);

  return (
    <Link to={link} ref={container} className="cardContainer">
      <motion.div
        style={{
          backgroundColor: background,
          //   scale,
          top: `calc(-10vh + ${i * 10}px)`,
        }}
        className="card"
      >
        <div className="card-content">
          <h2>{title}</h2>
          <h3>{location}</h3>
          <p>{details}</p>
        </div>

        <div className="imageContainer">
          <motion.div className="inner" style={{ scale: imageScale }}>
            <img src={src} alt="image" />
          </motion.div>
        </div>
      </motion.div>
    </Link>
  );
};

export default CardsMobile;

import React from "react";
import { motion } from "framer-motion";

const ProceedBtn = ({ handleNext, text, color = "#37bb9f", changeImg }) => {
  return (
    <motion.button
      className="btn action-btn tell-btn device-btn features-btn footer-btn contact-modal-btn selection-btn"
      whileHover="hover"
      initial="initial"
      animate="animate"
      onClick={handleNext}
    >
      <motion.div
        className="background"
        variants={{
          initial: {
            scaleY: 0,
            scaleX: 0.5,
            originY: 1,
            borderRadius: "50%",
            backgroundColor: "#ffffff",
          },
          hover: {
            scaleY: 1,
            scaleX: 1,
            borderRadius: "50px",
            backgroundColor: color,
            transition: {
              duration: 0.6,
              ease: "easeInOut",
              scaleX: { duration: 0.3 },
              scaleY: { delay: 0.1, duration: 0.5 },
            },
          },
          animate: {
            scaleY: 0,
            scaleX: 0.2,
            originY: 1,
            borderRadius: "50px",
            backgroundColor: "#ffffff",
            transition: { duration: 0.6, ease: "easeInOut" },
          },
        }}
      />
      <div className="text">{text}</div>
      <div className="icon-container">
        {changeImg ? (
          <img
            src="/svgs/blueprint_md.svg"
            style={{
              width: "30px",
              height: "30px",
            }}
            alt="icon"
          />
        ) : (
          <img src="/svgs/angle-right.svg" alt="icon" />
        )}
      </div>
    </motion.button>
  );
};

export default ProceedBtn;

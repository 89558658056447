import React from "react";
import "../../css/work/project.content.css";

const Ateni = () => {
  return (
    <div className="project-content" style={{ width: 8500 }}>
      {/* {title} */}
      <div className="screen-landing">
        <div className="screen-landing-title">Ateni</div>
        <div className="section-container">
          <div className="service-work">
            <p>ROLE/SERVICE</p>
            <h2>Branding & Design</h2>
          </div>
          <div className="location-work">
            <p>LOCATION</p>
            <h2>United Kingdom</h2>
          </div>
        </div>
      </div>
      <div className="project-full-image">
        <img
          src="/images/ateni_longimage.png"
          alt=""
          className="project-image"
          style={{ width: 5000 }}
        />
      </div>
      <div className="laptop-image-container">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/ateni_desktop.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="phones">
        <img src="/images/ateni_potrait_1.png" className="mobile" alt="" />
        <img src="/images/ateni_potrait_2.png" className="mobile" alt="" />
      </div>
    </div>
  );
};

export default Ateni;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFilled: false,
  isFilled2: false,
};

const isFilledSlice = createSlice({
  name: "isFilled",
  initialState,
  reducers: {
    toggleIsFilled: (state) => {
      state.isFilled = !state.isFilled;
    },
    toggleIsFilled2: (state) => {
      state.isFilled2 = !state.isFilled2;
    },
    toggleOff: (state) => {
      state.isFilled2 = false;
      state.isFilled = false;
    },
    setIsFilled: (state, action) => {
      state.isFilled = action.payload;
    },
    setIsFilled2: (state, action) => {
      state.isFilled2 = action.payload;
    },
  },
});

export const {
  toggleIsFilled,
  toggleIsFilled2,
  toggleOff,
  setIsFilled,
  setIsFilled2,
} = isFilledSlice.actions;
export default isFilledSlice.reducer;

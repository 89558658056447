import React, { useEffect, useRef, useState } from "react";
import ScreenLoadAnimation from "../components/ScreenLoadAnimation";
import { AnimatePresence, motion } from "framer-motion";
import "../css/home.css";
import Navbar from "../components/Navbar";
import StickyCursor from "../components/StickyCursor";
import MenuContainer from "../components/MenuContainer";
import BurgerMenu from "../components/BurgerMenu";
import HomeContentA from "../components/HomeContentA";
import HomeContentB from "../components/HomeContentB";
import HomeContentName from "../components/HomeContentName";
// import SlideInNotifications from "../components/Notification";
import HomeContentOrg from "../components/HomeContentOrg";
import HomeContentSercvice from "../components/HomeContentService";
import BackBtn from "../components/BackBtn";
import HomeContentDevice from "../components/HomeContentDevice";
import HomeContentTell from "../components/HomeContentTell";
import HomeContentFeatures from "../components/HomeContentFeatures";
import HomeContentThanks from "../components/HomeContentThanks";
import ContactModal from "../components/ContactModal";
import HomeSelectionSubmission from "../components/HomeSelectionSubmission";
import MenuMobileContainer from "../components/MenuMobileContainer";
import { useLocation, useNavigate } from "react-router-dom";
import PageTransition from "../components/PageTransition";

const Home = ({
  backExist,
  setBackExist,
  isHomeLink,
  setIsHomeLink,
  individualDetails,
  setIndividualDetails,
}) => {
  const handleNext = () => {
    setBackExist(true);
    setDirection(1); // Move right to left
    // if (currentIndex === 2) {
    setElementIndex({
      component: (
        <HomeContentTell
          setDesignService={setDesignService}
          handleNext={handleNext}
          color={color}
          text={text}
          setBackExist={setBackExist}
          individualDetails={individualDetails}
          setIndividualDetails={setIndividualDetails}
        />
      ),
      identifier: "HomeContentTell",
    });
    // }

    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
  };

  const handlePrev = () => {
    if (currentIndex === 0) {
      setBackExist(false);
    }

    setDirection(-1); // Move left to right
    if (currentIndex === 4) {
      setElementIndex({
        component: (
          <HomeContentSercvice
            handleNext={handleNext}
            color={color}
            text={text}
            setDesignService={setDesignService}
            individualDetails={individualDetails}
          />
        ),
        identifier: "HomeContentService",
      });
    }

    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselItems.length - 1 : prevIndex - 1
    );
  };

  const [screenLoadBool, setScreenLoadBool] = useState(true);
  const purposes = ["Businesses", "SMEs", "Individuals"];
  const [text, setText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);
  const colors = ["#078E35", "#8C078E", "#8E5807"];
  const [color, setColor] = useState(colors[0]);
  const [menuBool, setMenuBool] = useState(false);
  const [menuRepoBool, setMenuRepoBool] = useState(false);
  const stickyElement = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isIndividual, setIsIndividual] = useState(false);
  const [designService, setDesignService] = useState("design");
  const [isFilled, setIsFilled] = useState(false);
  const [isFilled2, setIsFilled2] = useState(false);
  const [isFilled3, setIsFilled3] = useState(false);
  const [contactBool, setContactBool] = useState(false);
  const [allSelectedFeatures, setAllSelectedFeatures] = useState([]);
  // const [isReload, setIsReload] = useState(false);
  const [navMobileThanks, setNavMobileThanks] = useState(false);

  // useEffect(() => {
  //   if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
  //     setIsReload(true);
  //     setScreenLoadBool(true);
  //   } else {
  //     setIsReload(false);
  //     setTimeout(() => {
  //       setScreenLoadBool(false);
  //     }, [300]);
  //   }
  // }, [performance.navigation]);

  useEffect(() => {
    if (isHomeLink) {
      setTimeout(() => {
        setScreenLoadBool(false);
      }, [300]);
    } else {
      setScreenLoadBool(true);
    }
  }, [isHomeLink]);

  useEffect(() => {
    if (currentIndex === 0) {
      setBackExist(false);
    }
  }, [currentIndex]);

  const [elementIndex, setElementIndex] = useState({
    component: (
      <HomeContentSercvice
        handleNext={handleNext}
        color={color}
        text={text}
        setDesignService={setDesignService}
        individualDetails={individualDetails}
      />
    ),
    identifier: "HomeContentService", // Use a string or key to identify the component
  });

  const carouselItems = [
    {
      id: 1,
      content: (
        <HomeContentA
          handleNext={handleNext}
          color={color}
          text={text}
          setNavMobileThanks={setNavMobileThanks}
        />
      ),
    },
    {
      id: 2,
      content: (
        <HomeContentB
          handleNext={handleNext}
          setIsIndividual={setIsIndividual}
        />
      ),
    },
    {
      id: 3,
      content: isIndividual ? (
        <HomeContentName
          individualDetails={individualDetails}
          setIndividualDetails={setIndividualDetails}
          handleNext={handleNext}
          color={color}
          text={text}
        />
      ) : (
        <HomeContentOrg
          handleNext={handleNext}
          color={color}
          text={text}
          individualDetails={individualDetails}
          setIndividualDetails={setIndividualDetails}
        />
      ),
    },
    {
      id: 4,
      content: elementIndex.component,
    },
    {
      id: 5,
      content: (
        <HomeContentDevice
          handleNext={handleNext}
          designService={designService}
          color={color}
          text={text}
          isFilled={isFilled}
          isFilled2={isFilled2}
          isFilled3={isFilled3}
          setIsFilled={setIsFilled}
          setIsFilled2={setIsFilled2}
          setIsFilled3={setIsFilled3}
        />
      ),
    },
    {
      id: 6,
      content: (
        <HomeContentFeatures
          handleNext={handleNext}
          color={color}
          text={text}
          designService={designService}
          setDesignService={setDesignService}
          mobile={isFilled}
          web={isFilled2}
          mobWeb={isFilled3}
          setContactBool={setContactBool}
          allSelectedFeatures={allSelectedFeatures}
          setAllSelectedFeatures={setAllSelectedFeatures}
        />
      ),
    },
    {
      id: 7,
      content: (
        <HomeSelectionSubmission
          handleNext={handleNext}
          designService={designService}
          color={color}
          text={text}
          allSelectedFeatures={allSelectedFeatures}
          individualDetails={individualDetails}
          isIndividual={isIndividual}
          setBackExist={setBackExist}
          setNavMobileThanks={setNavMobileThanks}
          mobileFilled={isFilled}
          webFilled={isFilled2}
          mobWebFilled={isFilled3}
        />
      ),
    },
    // {
    //   id: 8,
    //   content: (
    //     <HomeContentThanks handleNext={handleNext} color={color} text={text} />
    //   ),
    // },
  ];

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 1000 : -1000, // Direction is based on whether it's next or prev
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000, // Exit based on direction
        opacity: 0,
      };
    },
  };

  const handleMouseMove = (e) => {
    const screenWidth = window.innerWidth;
    const mouseX = e.clientX;

    // Check if the mouse is within the 30% of the right side of the screen
    if (mouseX > screenWidth * 0.7) {
      // Dynamically set the image width based on mouse position
      const width = screenWidth - mouseX;
      setImageWidth(width);
    } else {
      setImageWidth(0); // Set image width to 0 if mouse leaves the 30% zone
    }
  };

  useEffect(() => {
    const handleTyping = () => {
      const currentWordIndex = loopNum % purposes.length;
      const currentWord = purposes[currentWordIndex];

      // Set the color based on the current word index
      setColor(colors[currentWordIndex % colors.length]);

      if (!isDeleting) {
        // Typing the word
        setText(currentWord.substring(0, text.length + 1));
        setTypingSpeed(150);
        if (text === currentWord) {
          // When the word is fully typed, pause for a while before deleting
          setTimeout(() => setIsDeleting(true), 1000);
        }
      } else {
        // Deleting the word
        setText(currentWord.substring(0, text.length - 1));
        setTypingSpeed(50);
        if (text === "") {
          // Move to the next word
          setIsDeleting(false);
          setLoopNum(loopNum + 1);
        }
      }
    };

    const timer = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(timer);
  }, [text, isDeleting, loopNum, typingSpeed, purposes, colors]);

  // useEffect(() => {
  //   if (!isReload) {
  //     console.log("setScreenOpen");
  //     setTimeout(() => {
  //       setScreenLoadBool(false);
  //     }, [500]);
  //   } else {
  //     setScreenLoadBool(true);
  //   }
  // }, [isReload]);

  return (
    <div className="home-page">
      <AnimatePresence>
        {screenLoadBool && !isHomeLink && (
          <ScreenLoadAnimation
            setScreenLoadBool={setScreenLoadBool}
            screenLoadBool={screenLoadBool}
          />
        )}
      </AnimatePresence>
      {isHomeLink && <PageTransition pageTitle={"Get Started"} />}
      <AnimatePresence>
        {!screenLoadBool && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 1,
              ease: "backInOut",
            }}
            onMouseMove={handleMouseMove}
            className={`home-main h-screen w-full justify-center flex ${
              (currentIndex === 2 ||
                currentIndex === 5 ||
                currentIndex === 6 ||
                currentIndex === 0) &&
              "dark-background"
            }`}
          >
            <BackBtn handlePrev={handlePrev} backExist={backExist} />
            <div className="developed">
              <p>Designed & Developed by BluePrint</p>
            </div>
            <Navbar
              currentIndex={currentIndex}
              navMobileThanks={navMobileThanks}
            />
            {contactBool && (
              <div
                className="event-none"
                onClick={() => setContactBool(false)}
              ></div>
            )}
            <ContactModal
              contactBool={contactBool}
              setContactBool={setContactBool}
              handleNext={handleNext}
              individualDetails={individualDetails}
              setIndividualDetails={setIndividualDetails}
            />

            <AnimatePresence mode="wait">
              {menuBool && (
                <MenuContainer
                  error={false}
                  setCurrentIndex={setCurrentIndex}
                  setMenuBool={setMenuBool}
                  setBackExist={setBackExist}
                  setIsHomeLink={setIsHomeLink}
                />
              )}
            </AnimatePresence>
            <BurgerMenu
              ref={stickyElement}
              menuBool={menuBool}
              setMenuBool={setMenuBool}
              currentIndex={currentIndex}
            />

            {/* <div className="">
              <HomeContentA color={color} text={text} />
              <HomeContentA color={color} text={text} />
            </div> */}
            <AnimatePresence initial={false} custom={direction}>
              <motion.div
                className="h-screen w-screen group-contents"
                key={carouselItems[currentIndex].id}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 200, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
              >
                {carouselItems[currentIndex].content}
              </motion.div>
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!screenLoadBool && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 1,
              ease: "backInOut",
            }}
            onMouseMove={handleMouseMove}
            className={`home-main-mobile ${
              (currentIndex === 2 ||
                currentIndex === 5 ||
                currentIndex === 6 ||
                currentIndex === 0) &&
              "dark-background"
            }`}
          >
            <BackBtn handlePrev={handlePrev} backExist={backExist} />

            <img
              className="img-bg"
              src={
                currentIndex === 2 ||
                currentIndex === 5 ||
                currentIndex === 6 ||
                currentIndex === 0
                  ? "/svgs/home_background_black.svg"
                  : "/svgs/home_background.svg"
              }
              alt=""
            />
            <Navbar
              currentIndex={currentIndex}
              setMenuRepoBool={setMenuRepoBool}
              handlePrev={handlePrev}
              navMobileThanks={navMobileThanks}
              setCurrentIndex={setCurrentIndex}
              setIsHomeLink={setIsHomeLink}
            />
            {contactBool && (
              <div
                className="event-none"
                onClick={() => setContactBool(false)}
              ></div>
            )}
            <ContactModal
              contactBool={contactBool}
              setContactBool={setContactBool}
              handleNext={handleNext}
              individualDetails={individualDetails}
              setIndividualDetails={setIndividualDetails}
            />

            <AnimatePresence mode="wait">
              {menuBool && <MenuContainer />}
              {menuRepoBool && (
                <MenuMobileContainer
                  setIsHomeLink={setIsHomeLink}
                  setMenuRepoBool={setMenuRepoBool}
                />
              )}
            </AnimatePresence>
            <BurgerMenu
              menuBool={menuBool}
              setMenuBool={setMenuBool}
              currentIndex={currentIndex}
            />
            {/* <div className="">
              <HomeContentA color={color} text={text} />
              <HomeContentA color={color} text={text} />
            </div> */}
            <AnimatePresence initial={false} custom={direction}>
              <motion.div
                className="h-screen w-screen group-contents"
                key={carouselItems[currentIndex].id}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 200, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
              >
                {carouselItems[currentIndex].content}
              </motion.div>
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
      {/* {!screenLoadBool && <CodeSample imageWidth={imageWidth} />} */}

      {!screenLoadBool && (
        <StickyCursor
          currentIndex={currentIndex}
          stickyElement={stickyElement}
        />
      )}
    </div>
  );
};

export default Home;

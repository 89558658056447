import React, { useEffect } from "react";
import { motion, useMotionValue, useSpring } from "framer-motion";
import "../../css/about/sticky-menu-cursor.css";

const StickMenuContainer = ({ isPortofolio, modal, projects }) => {
  const mouse = {
    x: useMotionValue(0),
    y: useMotionValue(0),
  };

  const cursorSize = 400;

  const smoothOptions = { damping: 20, stiffness: 300, mass: 0.5 };

  const smoothMouse = {
    x: useSpring(mouse.x, smoothOptions),
    y: useSpring(mouse.y, smoothOptions),
  };

  const manageMouseMove = (e) => {
    const { clientX, clientY } = e;
    mouse.x.set(clientX - cursorSize / 2);
    mouse.y.set(clientY - cursorSize / 2);
  };

  useEffect(() => {
    window.addEventListener("mousemove", manageMouseMove);
    return () => {
      window.removeEventListener("mousemove", manageMouseMove);
    };
  });

  const { active, index } = modal;

  return (
    <motion.div
      className={`stick-menu-cursor ${isPortofolio && "visible"}`}
      style={{ left: smoothMouse.x, top: smoothMouse.y }}
    >
      <div
        // variants={scaleAnimation}
        // initial="initial"
        // animate={active ? "open" : "closed"}
        className="modalContainer"
        // style={{
        //   top: mousePosition.y - 175, // Adjust to center the modal vertically at the mouse cursor
        //   left: mousePosition.x - 200, // Adjust to center the modal horizontally at the mouse cursor
        // }}
      >
        <div style={{ top: index * -100 + "%" }} className="modalSlider">
          {projects.map((project, index) => (
            <div
              //   style={{ backgroundColor: project.color }}
              key={index}
              className="modal"
            >
              <h2>{project.title}</h2>
              <div
                className="img-project"
                style={{
                  backgroundImage: `url(${project.src})`,
                }}
              >
                {/* <div
                  className="proceed-icon"
                  style={{
                    background: project.background,
                    boxShadow: project.boxShadow,
                  }}
                >
                  <img src="/svgs/angle-right.svg" alt="icon" />
                </div> */}
              </div>
              <p>{project.details}</p>
            </div>
          ))}
        </div>
      </div>
      {/* <div
        className="cursor"
        // style={{ top: mousePosition.y - 40, left: mousePosition.x - 40 }}
      ></div>
      <div
        className="cursorLabel"
        // style={{ top: mousePosition.y - 40, left: mousePosition.x + 45 }}
      >
        View
      </div> */}
    </motion.div>
  );
};

export default StickMenuContainer;

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const CustomCursor = ({ setScreenLoadBool }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isCursorVisible, setIsCursorVisible] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
      setIsCursorVisible(true);
      clearTimeout(cursorTimeout); // Clear any existing timeout
      cursorTimeout = setTimeout(() => {
        setIsCursorVisible(false);
      }, 1000); // Hide cursor after 1 second of inactivity
    };

    let cursorTimeout = setTimeout(() => {
      setIsCursorVisible(false);
    }, 1000); // Initial timeout to hide cursor if no movement

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseleave", () => setIsCursorVisible(false)); // Hide cursor on mouse leave
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      clearTimeout(cursorTimeout); // Clean up timeout on unmount
    };
  }, []);

  return (
    <motion.div
      className={`custom-cursor ${isCursorVisible ? "visible" : "hidden"}`}
      initial={{ opacity: 0 }} // Initial state
      animate={{
        top: mousePosition.y,
        left: mousePosition.x,
        opacity: isCursorVisible ? 1 : 0, // Animate opacity based on visibility
      }}
      transition={{ type: "spring", stiffness: 600, damping: 60 }} // Smooth transition for movement
      onClick={() => setScreenLoadBool(false)}
    >
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: isCursorVisible ? 1 : 0 }}
        transition={{ duration: 0.5 }} // Fade in/out text
      >
        Click to <br /> open
      </motion.span>
    </motion.div>
  );
};

export default CustomCursor;

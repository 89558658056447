import React, { useRef } from "react";

const WorkCard = ({ i, background }) => {
  const container = useRef(null);

  return (
    <div ref={container} className="card">
      <div
        style={{
          backgroundColor: background,
          left: `calc(-10vh + ${i * 10}px)`,
        }}
        className="cd h-[500px] w-[300px] bg-white mr-[50px]"
      >
        <div className="left">
          <h1>SafulPay</h1>
          <h2>Nigeria</h2>
          <p>
            Blueprint transformed our vague ideas into a cohesive, stunning
            product. Their attention to detail is unmatched!
          </p>
        </div>
        <div className="right">
          <img src="/images/project_1.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default WorkCard;

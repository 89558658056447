import React, { useEffect } from "react";
import { motion, useMotionValue, useSpring } from "framer-motion";
import "../../css/work/sticky-link.css";

const StickyLink = ({ isCase, background, color = "black" }) => {
  const mouse = {
    x: useMotionValue(0),
    y: useMotionValue(0),
  };

  const cursorSize = 200;

  const smoothOptions = { damping: 10, stiffness: 200, mass: 0.5 };

  const smoothMouse = {
    x: useSpring(mouse.x, smoothOptions),
    y: useSpring(mouse.y, smoothOptions),
  };

  const manageMouseMove = (e) => {
    const { clientX, clientY } = e;
    mouse.x.set(clientX - cursorSize / 2);
    mouse.y.set(clientY - cursorSize / 2);
  };

  useEffect(() => {
    window.addEventListener("mousemove", manageMouseMove);
    return () => {
      window.removeEventListener("mousemove", manageMouseMove);
    };
  });

  return (
    <motion.a
      className={`link-cursor ${isCase && "visible"}`}
      style={{ left: smoothMouse.x, top: smoothMouse.y, background, color }}
    >
      View
      <br /> Website
    </motion.a>
  );
};

export default StickyLink;

import React from "react";
import { Link } from "react-router-dom";

const Work = ({ index, title, setModal, setIsPortofolio, category, link }) => {
  return (
    <Link
      to={link}
      className="work"
      onMouseEnter={() => {
        setModal({ active: true, index });
        setIsPortofolio(true);
      }}
      onMouseLeave={() => {
        setModal({ active: false, index });

        setIsPortofolio(false);
      }}
    >
      <div
        className="title"
        onMouseLeave={() => setModal({ active: false, index })}
      >
        {title}
      </div>
      <div
        className="category"
        onMouseLeave={() => setModal({ active: false, index })}
      >
        {category}
      </div>
    </Link>
  );
};

export default Work;

import React, { useEffect, useState } from "react";
import RoundedButton from "./RoundedButton";
import { motion } from "framer-motion";
import "../css/home-thanks.css";
import { useNavigate } from "react-router-dom";

const HomeContentThanks = ({ animated, dark }) => {
  const navigate = useNavigate();

  const scrollAnimation = {
    x: ["0%", "-100%"], // Scrolls the text from right to left
    transition: {
      repeat: Infinity,
      delay: 4, // Infinite loop
      duration: 400, // Duration of the scroll
      ease: "linear", // Smooth linear animation for seamless effect
    },
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Set initial mobile state based on screen width
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 575); // Adjust threshold as per mobile device width (e.g. 768px)
    };

    // Add event listener for screen resizing
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile(); // Initial check when component mounts

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  return (
    <motion.div
      initial={{ right: "-120%" }}
      animate={{ right: animated ? "0%" : "-120%" }}
      transition={{
        delay: 2.6,
        duration: 0.9,
        ease: "backInOut",
      }}
      className="home-thanks-container"
    >
      <div className="home-thanks-inner">
        <motion.div
          initial={{ opacity: 0, x: "300%" }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: "backInOut" }}
        >
          <RoundedButton
            height={isMobile && 230}
            width={isMobile && 230}
            handleNext={() => navigate("/projects")}
          >
            <p className="btn-text">Our Projects</p>
          </RoundedButton>
        </motion.div>
      </div>
      {animated && (
        <motion.div
          animate={scrollAnimation}
          style={{ color: dark && "#1d1d1d" }}
          className="thanks"
        >
          Thank you! One of our experts will be in touch with you shortly...
          Thank you! One of our experts will be in touch with you shortly...
          Thank you! One of our experts will be in touch with you shortly...
          Thank you! One of our experts will be in touch with you shortly...
          Thank you! One of our experts will be in touch with you shortly...
          Thank you! One of our experts will be in touch with you shortly...
          Thank you! One of our experts will be in touch with you shortly...
          Thank you! One of our experts will be in touch with you shortly...
          Thank you! One of our experts will be in touch with you shortly...
          Thank you! One of our experts will be in touch with you shortly...
          Thank you! One of our experts will be in touch with you shortly...
          Thank you! One of our experts will be in touch with you shortly...
          Thank you! One of our experts will be in touch with you shortly...
          Thank you! One of our experts will be in touch with you shortly...
        </motion.div>
      )}
    </motion.div>
  );
};

export default HomeContentThanks;

import React, { useEffect, useState } from "react";
import PageTransition from "../components/PageTransition";
import Navbar from "../components/Navbar";
import { AnimatePresence } from "framer-motion";
import MenuMobileContainer from "../components/MenuMobileContainer";
import "../css/work/work-mobile.css";
import WorksMobile from "../components/work components/WorksMobile";
import Lenis from "@studio-freight/lenis";
import AboutMobileFooter from "../components/about mobile components/AboutMobileFooter";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const WorkMobilePage = ({ setIsHomeLink, screenLoadBool }) => {
  const [menuRepoBool, setMenuRepoBool] = useState(false);

  //   useEffect(() => {
  //     const lenis = new Lenis();

  //     function raf(time) {
  //       lenis.raf(time);
  //       requestAnimationFrame(raf);
  //     }

  //     requestAnimationFrame(raf);
  //   });

  return (
    <div className="work-mobile">
      <Helmet>
        <title>
          Blueprint - PROJECTS | Showcasing Our Innovative Digital Solutions
        </title>
      </Helmet>
      {!screenLoadBool && (
        <>
          <img src="/svgs/home_mob_bg.svg" alt="" className="mobile-bg" />
          <Navbar
            setIsHomeLink={setIsHomeLink}
            setMenuRepoBool={setMenuRepoBool}
          />
          <AnimatePresence mode="wait">
            {menuRepoBool && (
              <MenuMobileContainer
                setIsHomeLink={setIsHomeLink}
                setMenuRepoBool={setMenuRepoBool}
              />
            )}
          </AnimatePresence>
          <main>
            <h1 className="work-title">ALL PROJECTS</h1>
            <Link to="/projects/project-flex" className="view-project-flex">
              View Projects Flex
            </Link>
            <WorksMobile />
            <AboutMobileFooter />
          </main>
        </>
      )}
      <PageTransition pageTitle={"projects"} />
    </div>
  );
};

export default WorkMobilePage;

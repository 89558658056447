import React from "react";
import RoundedButton from "../RoundedButton";
import { Link } from "react-router-dom";
import "../../css/work/next-case.css";

const LastCase = ({}) => {
  return (
    <div className="next-case last-case">
      <Link to="/projects/project-flex" className="view">
        <p>View Project Flex</p>
        <img src="/svgs/flex-arrow.svg" alt="" />
      </Link>
    </div>
  );
};

export default LastCase;

import React from "react";

const AboutMobileHelp = () => {
  return (
    <div className="about-mobile-help">
      <h1 className="h1">WE CAN HELP YOU WITH</h1>
      <div className="first-frame">
        <div className="cards">
          <div className="card">
            <h1>Design</h1>
          </div>
          <div className="card">
            <h2>1. User Research</h2>
            <p>
              We begin by understanding your users through comprehensive
              research, interviews, and data analysis. This helps us align the
              design with user needs and business goals.
            </p>
          </div>
          <div className="card">
            <h2>2. Wireframing & Prototyping</h2>
            <p>
              Our team creates wireframes that outline the layout and structure
              of your app or website. This is followed by interactive
              prototypes, allowing you to visualize the user experience and
              provide feedback before moving to the next phase.
            </p>
          </div>
          <div className="card">
            <h2>3. Visual Design</h2>
            <p>
              Once the structure is defined, we focus on the look and feel of
              the product. We combine color schemes, typography, and branding
              elements to create a visually appealing design that enhances
              usability.
            </p>
          </div>
          <div className="card">
            <h2>4. Usability Testing</h2>
            <p>
              We conduct thorough usability testing to gather real user
              feedback. This allows us to make adjustments before development,
              ensuring the product meets user expectations and offers a seamless
              experience.
            </p>
          </div>
        </div>
      </div>
      <div className="middle-card">
        <img src="/svgs/Arrow-down-mobile.svg" className="left" alt="" />
        <img src="/svgs/Arrow-up-mobile.svg" className="right" alt="" />
        <h1>Transition & Collaboration Approach</h1>
        <p>
          1. Design-First, Then Development
          <br /> 2. Agile Collaboration: Sprint-Based Workflow
          <br /> 3. Simultaneous Design & Development
        </p>
      </div>
      <div className="first-frame">
        <div className="cards">
          <div className="card">
            <h1>Development</h1>
          </div>
          <div className="card">
            <h2>1. Custom Web & App Development</h2>
            <p>
              Our development team builds powerful and scalable websites and
              apps tailored to your unique business needs. We follow a
              meticulous process to ensure clean, efficient code that powers
              fast, responsive, and secure digital experiences.
            </p>
          </div>
          <div className="card">
            <h2>2. Full-Stack Development</h2>
            <p>
              From front-end user interfaces to back-end server architecture, we
              handle every aspect of the development process. Whether it’s
              integrating APIs, databases, or advanced functionalities, we
              ensure everything works harmoniously together.
            </p>
          </div>
          <div className="card">
            <h2>3. Cross-Platform Solutions</h2>
            <p>
              Our development process ensures that your product functions
              seamlessly across different platforms—whether it's desktop,
              mobile, or tablet. We prioritize responsive design and
              cross-platform compatibility to reach the widest audience
              possible.
            </p>
          </div>
          <div className="card">
            <h2>4. Quality Assurance & Testing</h2>
            <p>
              We conduct thorough usability testing to gather real user
              feedback. This allows us to make adjustments before development,
              ensuring the product meets user expectations and offers a seamless
              experience.
            </p>
          </div>
          <div className="card">
            <h2>5. Ongoing Maintenance & Updates</h2>
            <p>
              After launch, we continue to support you with regular updates, new
              features, and maintenance. Our goal is to keep your app or website
              running smoothly, secure, and up-to-date with the latest
              technology advancements.
            </p>
          </div>
        </div>
      </div>
      <div className="additional">
        + <br />
        Additional
        <br /> Services
      </div>
      <div className="other-features">
        <div className="card">
          <h2>Branding</h2>
          <p>
            We provide branding services to help you establish a unique and
            recognizable identity. From logos to complete brand guidelines, we
            create visual elements that communicate your brand’s values
            effectively.
          </p>
        </div>
        <div className="card">
          <h2>SEO Optimization</h2>
          <p>
            Our websites and apps are built with SEO in mind, ensuring they rank
            well on search engines. We implement best practices to improve
            visibility, drive traffic, and help your business grow organically.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutMobileHelp;

import React from "react";
import "../../css/about/footer.css";
import BtnMagnetic from "../ButtonMagnetic";
import ProceedBtn from "../ProceedBtn";
import { Link, useNavigate } from "react-router-dom";
import GrabMagnetic from "../GrabMagnetic";

const Footer = ({ setIsHomeLink }) => {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="ctn">
        <h3>
          Let's Build <br />
          Something <br />
          Amazing
        </h3>
        <p>
          Your Vision, Our BluePrint for Success. At Blueprint, we don’t just
          design products; we craft experiences that resonate. Every project we
          undertake is an opportunity to turn your unique vision into a reality
          that stands out.
          <br />
          <br /> Ready to see your ideas come to life? Let’s build your
          blueprint for success together.
        </p>
        <div className="btn-ctrn">
          <BtnMagnetic>
            <ProceedBtn
              text="Get started with BluePrint"
              changeImg={true}
              handleNext={() => navigate("/")}
            />
          </BtnMagnetic>
        </div>
      </div>
      <div className="footer-btm">
        <Link to="/" onClick={() => setIsHomeLink(true)} className="logo">
          <div className="scope">
            <p>BluePrint</p>
          </div>
          <img src="/svgs/Blueprint_footer.svg" alt="" />
        </Link>
        <div className="links">
          <div className="a">
            <GrabMagnetic>
              <Link to="/projects">Projects</Link>
            </GrabMagnetic>
          </div>
          <div className="a">
            <GrabMagnetic>
              <Link to="/about-us">About Us</Link>
            </GrabMagnetic>
          </div>
          <div className="a">
            <GrabMagnetic>
              <Link to="/" onClick={() => setIsHomeLink(true)}>
                Get Started
              </Link>
            </GrabMagnetic>
          </div>
          <div className="a">
            <GrabMagnetic>
              <Link to="/contact-us">Contact Us</Link>
            </GrabMagnetic>
          </div>
        </div>
        <div className="social-links">
          <p>Follow Us</p>
          <div className="socials">
            <Link
              to="https://www.linkedin.com/company/chooseblueprint"
              className="social"
            >
              <GrabMagnetic>
                <img src="/svgs/linkedin_last_black.svg" alt="" />
              </GrabMagnetic>
            </Link>
            <div className="social">
              <GrabMagnetic>
                <img src="/svgs/instagram.svg" alt="" />
              </GrabMagnetic>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

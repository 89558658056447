import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import "../../css/about/meet-team.css";
import RoundedButton from "../RoundedButton";
import { Link } from "react-router-dom";

const MeetTheTeam = () => {
  return (
    <div className="meet-container">
      <div className="content">
        <div className="ctn-title">
          <h4>Meet the team</h4>
          <div className="stretch">
            <RoundedButton
              backgroundColor="#37bb9ed3"
              height={200}
              width={200}
              handleNext={() => {
                // Navigate to next case page
              }}
            >
              <div className="btn-content">
                <p>Hover</p>
                <img src="/svgs/Arrow-slant.svg" alt="" />
              </div>
            </RoundedButton>
          </div>
        </div>
        <div className="keys">
          <h1>Innovative.</h1>
          <h1>User-Centric.</h1>
          <h1>Speed.</h1>
        </div>
      </div>

      <div className="images">
        {/* First Image */}
        <Link
          to="https://www.linkedin.com/in/otunbamarcus/"
          target="_blank"
          className={`img`}
        >
          <img src="/images/marcus_head_transparent.png" alt="" />
          <div className="ctn">
            <div className="left">
              <h3>Otunba Marcus</h3>
              <p>
                My goal is to build fast,
                <br /> scalable solutions that
                <br /> enhance user experiences <br />
                across all platforms
              </p>
            </div>
            <div className="right">
              <div className="scope">
                <div className="career">Developer</div>
              </div>
              <div className="social">
                <div className="icon">
                  <img src="/svgs/linked_work.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </Link>

        {/* Second Image */}

        <Link
          to="https://www.linkedin.com/in/yusuf-oluwatimileyin/"
          target="_blank"
          className={`img`}
        >
          <img src="/images/timi_head_transparent.png" alt="" />
          <div className="ctn">
            <div className="left">
              <h3>Bamise Timi</h3>
              <p>
                At Blueprint, I merge
                <br /> creativity with functionality to
                <br /> ensure every design is as <br />
                practical as it is beautiful.
              </p>
            </div>
            <div className="right">
              <div className="scope">
                <div className="career">Designer</div>
              </div>
              <div className="social">
                <div className="icon">
                  <img src="/svgs/linked_work.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MeetTheTeam;

import React from "react";
import ActionBtn from "./ActionBtn";
import BtnMagnetic from "./ButtonMagnetic";

const HomeContentB = ({ handleNext, setIsIndividual }) => {
  const handleIndividual = () => {
    setIsIndividual(true);
    handleNext();
  };

  const handleOrganization = () => {
    setIsIndividual(false);
    handleNext();
  };

  return (
    <div className="home-content home-b">
      <div className="home-inner">
        <h1>
          We would like to <br /> know more about you
        </h1>

        <h4>
          Are you an Individual or representing an
          <br /> Organization?
        </h4>
        {/* <GrabMagnetic>
          <Button_A color={color} handleNext={handleNext} />
        </GrabMagnetic> */}
        <div className="buttons-container">
          <BtnMagnetic>
            <ActionBtn text="I’m an individual" handleNext={handleIndividual} />
          </BtnMagnetic>
          <BtnMagnetic>
            <ActionBtn
              text="I’m with an Organization"
              handleNext={handleOrganization}
            />
          </BtnMagnetic>
        </div>
      </div>
    </div>
  );
};

export default HomeContentB;

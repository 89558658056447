import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Work from "./Work";
import Modal from "./Modal";

gsap.registerPlugin(ScrollTrigger);

const PortofolioContent = ({ setIsPortofolio, setModal, projects }) => {
  //   const portofolioWrapperRef = useRef(null);
  const portofolioWrapperRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  //   useEffect(() => {
  //     // Animate the portfolio wrapper as the user scrolls
  //     gsap.to(portofolioWrapperRef.current, {
  //       y: -1000, // Adjust this value based on how far you want the portfolio-wrapper to scroll
  //       ease: "none",
  //       scrollTrigger: {
  //         trigger: portofolioWrapperRef.current, // Set the wrapper as the scroll trigger
  //         start: "top center", // When the top of the wrapper hits the center of the viewport
  //         end: "bottom top", // When the bottom of the wrapper hits the top of the viewport
  //         scrub: 0.2, // Sync animation with scroll
  //         markers: false, // Set to true for debugging
  //       },
  //     });
  //   }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    const wrapper = portofolioWrapperRef.current;

    if (wrapper) {
      wrapper.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (wrapper) {
        wrapper.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, []);

  return (
    <div className="portofolio-content-container" ref={portofolioWrapperRef}>
      <div className="portofolio">
        <div
          className="portofolio-wrapper"
          onMouseEnter={() => setIsPortofolio(true)}
          onMouseLeave={() => setIsPortofolio(false)}
        >
          {projects.map((project, index) => (
            <Work
              key={index}
              index={index}
              setModal={setModal}
              setIsPortofolio={setIsPortofolio}
              {...project}
            />
          ))}
        </div>
        {/* <Modal
          modal={modal}
          projects={projects}
          mousePosition={mousePosition}
        /> */}
      </div>
    </div>
  );
};

export default PortofolioContent;

import React from "react";
import GrabMagnetic from "./GrabMagnetic";
import Button_A from "./Button_A";
import { useDispatch } from "react-redux";
import { toggleOff } from "../redux/isFilledSlice";
import RoundedButton from "./RoundedButton";

const HomeContentA = ({ color, text, handleNext, setNavMobileThanks }) => {
  const dispatch = useDispatch();

  return (
    <div className="home-content home-getStarted">
      <div className="home-inner">
        <h1 className="first">
          Blueprint is the <br />
          Future of Design <br /> and Innovation <br /> for{" "}
          <span
            style={{
              color,
            }}
            className="normal"
          >
            {text}
          </span>
          <span className="cursor" style={{ backgroundColor: color }}>
            |
          </span>
        </h1>
        <h1 className="second">
          Blueprint is the Future of Design and Innovation for
          <br />
          <span
            style={{
              color,
            }}
            className="normal"
          >
            {text}
          </span>
          <span className="cursor" style={{ backgroundColor: color }}>
            |
          </span>
        </h1>
        <div className="wrapper">
          <p className="content-a">
            Recognizing the critical importance of "Speed" <br /> in both design
            and development, every <br />
            moment counts.
          </p>
          <RoundedButton
            backgroundColor="#37bb9ed3"
            height={199}
            width={300}
            start={true}
            handleNext={() => {
              dispatch(toggleOff());
              handleNext();
            }}
          >
            <div className="wrap">
              <p className="btn-text">
                Start a <br /> project
              </p>
              <img src="/svgs/Arrow.svg" alt="" />
            </div>
          </RoundedButton>
        </div>
        <div className="wrapper second">
          <p className="content-a">
            Recognizing the critical importance of "Speed" in both design and
            development, every moment counts.
          </p>
          <div className="stretch">
            <RoundedButton
              backgroundColor="#37bb9ed3"
              height={100}
              width={150}
              start={true}
              handleNext={() => {
                dispatch(toggleOff());
                handleNext();
                setNavMobileThanks(false);
              }}
            >
              <div className="wrap">
                <p className="btn-text" style={{ textAlign: "start" }}>
                  Start a <br /> project
                </p>
                <img src="/svgs/Arrow.svg" alt="" />
              </div>
            </RoundedButton>
          </div>
        </div>
        {/* <GrabMagnetic>
          <Button_A
            color={color}
            handleNext={() => {
              dispatch(toggleOff());
              handleNext();
            }}
          />
        </GrabMagnetic> */}
      </div>
    </div>
  );
};

export default HomeContentA;

import React, { useEffect, useState } from "react";
import ProceedBtn from "./ProceedBtn";
import BtnMagnetic from "./ButtonMagnetic";
import { motion } from "framer-motion";
import HomeContentThanks from "./HomeContentThanks";
import Lottie from "lottie-react";
import Loader from "../lotties/Animation - 1728381102131.json";
import { useSelector } from "react-redux";
import emailjs from "emailjs-com";

const HomeSelectionSubmission = ({
  allSelectedFeatures,
  individualDetails,
  isIndividual,
  setNavMobileThanks,
  setBackExist,
  mobileFilled,
  webFilled,
  mobWebFilled,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [imgBool, setImgBool] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [loadingBool, setLoadingBool] = useState(false);
  const [service, setService] = useState("");
  const [platform, setPlatform] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const isFilled = useSelector((state) => state.isFilledState.isFilled);
  const isFilled2 = useSelector((state) => state.isFilledState.isFilled2);

  useEffect(() => {
    if (isFilled && isFilled2) {
      setService("Des-Dev");
    } else if (isFilled) {
      setService("Design");
    } else {
      setService("Development");
    }
  }, [isFilled, isFilled2]);

  useEffect(() => {
    if (mobileFilled) {
      setPlatform("Mobile");
    } else if (webFilled) {
      setPlatform("Web");
    } else if (mobWebFilled) {
      setPlatform("Mobile and Web");
    }
  }, []);

  useEffect(() => {
    if (!isAnimating) {
      setNavMobileThanks(false);
    }
  }, [isAnimating]);

  useEffect(() => {
    // Set initial mobile state based on screen width
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust threshold as per mobile device width (e.g. 768px)
    };

    // Add event listener for screen resizing
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile(); // Initial check when component mounts

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  const imgAnimationVariants = {
    hidden: {
      right: isMobile ? "-20%" : "-10%", // Start from -10% from the right
    },
    animate: {
      right: isMobile
        ? ["-20%", "-18%", "40%", "40%"]
        : ["-10%", "2%", "43%", "43%"], // Adjusted for mobile
      scale: [1, 1, 1, isMobile ? 1.8 : 2], // Scale up to 2 at 43%
      transition: {
        duration: 2, // Total duration for the entire animation sequence
        times: [0, 0.2, 0.8, 1, 1], // Define the timing
        ease: "backInOut",
      },
    },
    stop: {
      right: "-10%",
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const imgAnimationVariants2 = {
    hidden: {
      right: isMobile ? "40%" : "43%", // Start from -10% from the right
    },
    bounceOut: {
      right: isMobile ? ["40%", "0%", "200%"] : ["43%", "0%", "120%"],
      transition: {
        delay: 0.2,
        duration: 1,
        times: [0, 0.6, 1],
        ease: ["easeInOut", "easeOut", "backInOut"],
      },
    },
  };

  useEffect(() => {
    if (isAnimating) {
      // Set a timeout that matches the duration of the image animation
      const timer = setTimeout(() => {
        setImgBool(true);
      }, 2000); // Duration of the image animation (2 seconds)

      // Clear the timeout if the component unmounts or if isAnimating changes
      return () => clearTimeout(timer);
    }
  }, [isAnimating]);

  function capitalizeFirstLetter(word) {
    if (!word) return ""; // Handle cases where the word is empty or null
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  const handleSubmit = () => {
    setErrorMessage("");
    setLoadingBool(true);

    emailjs
      .send(
        "service_00ycw0m",
        "template_ew3vo6f",
        {
          name: individualDetails.name,
          category: isIndividual ? "Individual" : "Organization",
          service,
          platform,
          business_about: individualDetails.buisnessAbout,
          contact_details: individualDetails.contactDetails,
          selections: JSON.stringify(allSelectedFeatures),
        },
        "badD0FjqLJBP_4fNT"
      )
      .then((response) => {
        if (response.status === 200) {
          setLoadingBool(false);
          setIsAnimating(!isAnimating);
          setBackExist(false);
          setNavMobileThanks(true);
        } else {
          setErrorMessage("Failed to send message, please try again");
          setLoadingBool(false);
        }
      })
      .catch((err) => {
        setErrorMessage("Failed to send message, please try again");
        setLoadingBool(false);
      });
  };

  return (
    <div className="home-content home-device home-selection">
      <motion.div
        initial={{ x: "0%" }}
        animate={{ x: isAnimating ? "-200%" : "0%" }}
        transition={{
          delay: 0.6,
          duration: 1,
          ease: "circInOut",
        }}
        className="home-inner"
      >
        <h1 className="sel-h1">
          Let's walk through your details & <br />
          selection before submitting
        </h1>
        <h1 className="sel-h1-mobile">
          Let's walk through your details & selection before submitting
        </h1>
        {loadingBool && (
          <div className="animation">
            <Lottie animationData={Loader} />
          </div>
        )}
        <div className="selection-modal">
          {errorMessage && (
            <div className="section error-section">
              <p>{errorMessage}</p>
            </div>
          )}
          <div className="section">
            <div className="section-title">Your Name</div>
            <div className="section-purpose">
              {capitalizeFirstLetter(individualDetails.name)}
            </div>
          </div>
          <div className="section">
            <div className="section-title">Category</div>
            <div className="section-purpose">
              {isIndividual ? "Individual" : "Organization"}
            </div>
          </div>
          <div className="section">
            <div className="section-title">Contact Details</div>
            <div className="section-purpose">
              {individualDetails.contactDetails}
            </div>
          </div>
          <div className="your-selection">
            <h2>Your selection</h2>
            <div className="tags">
              {allSelectedFeatures.map((feature, index) => (
                <div key={index} className="tag">
                  {feature.title}
                </div>
              ))}
            </div>
          </div>
        </div>

        <BtnMagnetic>
          <ProceedBtn
            text="Confirm and submit"
            handleNext={handleSubmit}
            color="#37bb9ed3"
          />
        </BtnMagnetic>
      </motion.div>

      <HomeContentThanks animated={isAnimating} />

      <motion.img
        src="/svgs/blueprint_lg.svg"
        className={`img-bp ${imgBool && "hide"}`}
        alt=""
        initial="hidden" // Initial state off-screen
        animate={isAnimating ? "animate" : "stop"} // Animate when `isAnimating` is true
        variants={imgAnimationVariants}
      />
      <motion.img
        initial="hidden"
        animate={imgBool ? "bounceOut" : "hidden"} // Animate when `imgBool` is true
        variants={imgAnimationVariants2}
        src="/svgs/blueprint_lg.svg"
        className={`img-bpp ${imgBool && "reveal"}`}
        alt=""
      />
    </div>
  );
};

export default HomeSelectionSubmission;

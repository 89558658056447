import React from "react";
import "../../css/work/project.content.css";

const ProjectFlexLanding = () => {
  return (
    <div className="project-content">
      <div className="screen-landing">
        <div className="screen-landing-title">
          PROJECT
          <br /> FLEX
        </div>
        <div className="section-container">
          <div className="service-work">
            <p>ROLE/SERVICE</p>
            <h2>Design & Development</h2>
          </div>
          <div className="location-work">
            <p>LOCATION</p>
            <h2>Global</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectFlexLanding;

import React, { useRef } from "react";
import WorkCard from "./WorkCard";

const WorkLandScapePortfolio = () => {
  const container = useRef(null);

  const projects = [
    {
      title: "C2 Montreal",
      src: "/img/airplane.webp",
      color: "#eeff00",
      background: "rgba(41, 47, 69, 0.80)",
      boxShadow: "0px 4px 50px 0px rgba(231, 255, 133, 0.64)",
    },
    {
      title: "Office Studio",
      src: "/img/family-man.webp",
      color: "#00ffb3",
      background: "rgba(139, 0, 0, 0.80)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 179, 0.64)",
    },
    {
      title: "Locomotive",
      src: "locomotive.png",
      color: "#ff00ee",
      background: "rgba(205, 92, 0, 0.80)",
      boxShadow: "0px 4px 50px 0px rgba(255, 0, 238, 0.64)",
    },
    {
      title: "Silencto",
      src: "silencio.png",
      color: "#00ff2f",
      background: "rgba(184, 134, 11, 0.80)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 47, 0.64)",
    },
    {
      title: "Silencio",
      src: "silencio.png",
      color: "#ff5733",
      background: "rgba(0, 100, 0, 0.80)",
      boxShadow: "0px 4px 50px 0px rgba(255, 53, 33, 0.64)",
    },
    {
      title: "Silencio",
      src: "silencio.png",
      color: "#007bff",
      background: "rgba(0, 139, 139, 0.80)",
      boxShadow: "0px 4px 50px 0px rgba(0, 123, 255, 0.64)",
    },
    {
      title: "Silencio",
      src: "silencio.png",
      color: "#ffcc00",
      background: "rgba(0, 0, 139, 0.80)",
      boxShadow: "0px 4px 50px 0px rgba(255, 204, 0, 0.64)",
    },
    {
      title: "Silencio",
      src: "silencio.png",
      color: "#8A2BE2",
      background: "rgba(48, 25, 52, 0.80)",
      boxShadow: "0px 4px 50px 0px rgba(75, 0, 130, 0.64)",
    },
    {
      title: "Silencio",
      src: "silencio.png",
      color: "#FFD700",
      background: "rgba(138, 43, 226, 0.80)",
      boxShadow: "0px 4px 50px 0px rgba(255, 193, 7, 0.64)",
    },
    {
      title: "Silencio",
      src: "silencio.png",
      color: "#FF1493",
      background: "rgba(139, 0, 139, 0.80)",
      boxShadow: "0px 4px 50px 0px rgba(255, 105, 180, 0.64)",
    },
  ];

  return (
    <div className="work-landscape">
      <div className="landscape-container">
        <div className="cards">
          {projects.map((project, index) => {
            return <WorkCard key={index} i={index} {...project} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default WorkLandScapePortfolio;

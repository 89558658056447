import React, { useState } from "react";
import "../../css/about/about-mobile-section.css";
import Navbar from "../Navbar";
import { AnimatePresence } from "framer-motion";
import MenuMobileContainer from "../MenuMobileContainer";
import AboutMobileLanding from "./AboutMobileLanding";
import AboutMobileHelp from "./AboutMobileHelp";
import AboutMobilePortofolio from "./AboutMobilePortofolio";
import AboutMobileMeetOverview from "./AboutMobileMeetOverview";
import AboutMobileFooter from "./AboutMobileFooter";
import { Helmet } from "react-helmet";

const AboutMobileSection = ({ setIsHomeLink, individualDetails }) => {
  const [menuRepoBool, setMenuRepoBool] = useState(false);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="about-mobile-section">
      <Helmet>
        <title>
          Blueprint - About Us | Your Partner in Crafting Innovative Digital
          Experiences
        </title>
      </Helmet>
      <img src="/svgs/home_mob_bg.svg" alt="" className="mobile-bg" />
      <Navbar setMenuRepoBool={setMenuRepoBool} setIsHomeLink={setIsHomeLink} />
      <AnimatePresence mode="wait">
        {menuRepoBool && (
          <MenuMobileContainer
            setIsHomeLink={setIsHomeLink}
            setMenuRepoBool={setMenuRepoBool}
          />
        )}
      </AnimatePresence>

      <div className="about-mobile-container">
        <AboutMobileLanding individualDetails={individualDetails} />
        <AboutMobileHelp />
        <AboutMobilePortofolio />
        <AboutMobileMeetOverview />
        <AboutMobileFooter setIsHomeLink={setIsHomeLink} />
        <div className="scroll-bottom" onClick={scrollToBottom}>
          <img src="/svgs/angle-down.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default AboutMobileSection;

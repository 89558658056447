import React from "react";
import "../../css/about/about-portofolio.css";
import BtnMagnetic from "../ButtonMagnetic";
import ProceedBtn from "../ProceedBtn";
import PortofolioContent from "./PortofolioContent";
import { useNavigate } from "react-router-dom";

const AboutPortofoilo = ({ setIsPortofolio, setModal, projects }) => {
  const navigate = useNavigate();

  return (
    <div className="about-portofolio">
      <h1>PROJECTS</h1>
      <div className="content">
        <div className="group">
          <h3>
            Our Blueprint for Success: Projects That Speak
            <br /> for Themselves
          </h3>
          {/* <p className="first">
            Explore the projects we’ve crafted with passion,
            <br /> precision, and a deep understanding of user
            <br /> needs.
          </p> */}
        </div>
        <p>
          At Blueprint, we believe that our work should
          <br /> speak louder than words. Below, you’ll find a <br />
          curated selection of our projects across various
          <br /> industries. Each project is a testament to our
          <br /> commitment to delivering exceptional design and
          <br /> development solutions tailored to our clients’
          <br /> unique needs.
        </p>
        <div className="btn-ctrn">
          <BtnMagnetic>
            <ProceedBtn
              text="All projects"
              handleNext={() => navigate("/projects")}
            />
          </BtnMagnetic>
        </div>
      </div>

      <PortofolioContent
        setModal={setModal}
        setIsPortofolio={setIsPortofolio}
        projects={projects}
      />
    </div>
  );
};

export default AboutPortofoilo;

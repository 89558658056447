import React, { useState, useEffect } from "react";
import ProceedBtn from "./ProceedBtn";
import BtnMagnetic from "./ButtonMagnetic";
import { motion } from "framer-motion";
import ContentService from "./CtnService";

const HomeContentTell = ({
  handleNext,
  setDesignService,
  setBackExist,
  individualDetails,
  setIndividualDetails,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [imgBool, setImgBool] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [shouldShake, setShouldShake] = useState(false);

  useEffect(() => {
    if (shouldShake) {
      setTimeout(() => {
        setShouldShake(false);
      }, 2000);
    }
  }, [shouldShake]);

  useEffect(() => {
    // Set initial mobile state based on screen width
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust threshold as per mobile device width (e.g. 768px)
    };

    // Add event listener for screen resizing
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile(); // Initial check when component mounts

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  useEffect(() => {
    setTextValue(individualDetails.buisnessAbout);
  }, [individualDetails]);

  // Animation variants for the img
  const imgAnimationVariants = {
    hidden: {
      right: isMobile ? "-20%" : "-10%", // Start from -10% from the right
    },
    animate: {
      right: isMobile
        ? ["-20%", "-18%", "40%", "40%"]
        : ["-10%", "11%", "43%", "43%"], // Adjusted for mobile
      scale: [1, 1, 1, isMobile ? 1.8 : 2], // Scale up to 2 at 43%
      transition: {
        duration: 2, // Total duration for the entire animation sequence
        times: [0, 0.2, 0.8, 1, 1], // Define the timing
        ease: "backInOut",
      },
    },
    stop: {
      right: "-10%",
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const imgAnimationVariants2 = {
    hidden: {
      right: isMobile ? "40%" : "43%", // Start from -10% from the right
    },
    bounceOut: {
      right: isMobile ? ["40%", "0%", "200%"] : ["43%", "0%", "120%"],
      transition: {
        delay: 0.2,
        duration: 1,
        times: [0, 0.6, 1],
        ease: ["easeInOut", "easeOut", "backInOut"],
      },
    },
  };

  useEffect(() => {
    if (isAnimating) {
      // Set a timeout that matches the duration of the image animation
      const timer = setTimeout(() => {
        setImgBool(true);
      }, 2000); // Duration of the image animation (2 seconds)

      // Clear the timeout if the component unmounts or if isAnimating changes
      return () => clearTimeout(timer);
    }
  }, [isAnimating]);

  const [textValue, setTextValue] = useState("");

  // Handle the change in textarea
  const handleTextChange = (e) => {
    setTextValue(e.target.value);
  };

  return (
    <div className="home-content home-tell home-service">
      <motion.div
        initial={{ x: 0 }}
        animate={{ x: isAnimating ? "-200%" : 0 }}
        transition={{
          delay: 0.6,
          duration: 1,
          ease: "circInOut",
        }}
        className={`home-inner `}
      >
        <h4>
          Tell us a bit about what your <br /> business does.
        </h4>
        <h4 className="tell-h4-mobile">
          Tell us a bit about what your business does.
        </h4>
        <textarea
          placeholder="Type here"
          value={textValue}
          onChange={handleTextChange}
          className={`${shouldShake && "shake-animation"}`}
          style={{ border: shouldShake && "1px solid #ffb7b7" }}
        />
        <BtnMagnetic>
          <ProceedBtn
            text="Proceed"
            handleNext={() => {
              if (!textValue) {
                setShouldShake(true);
              } else {
                setIsAnimating(!isAnimating);
                setBackExist(false);
                setIndividualDetails({
                  ...individualDetails,
                  buisnessAbout: textValue,
                });
              }
            }}
            color="#37bb9ed3"
          />
        </BtnMagnetic>
      </motion.div>
      <ContentService
        setDesignService={setDesignService}
        animated={isAnimating}
        handleNext={handleNext}
        individualDetails={individualDetails}
        setBackExist={setBackExist}
      />

      {/* Animated Image */}
      <motion.img
        src="/svgs/blueprint_lg.svg"
        className={`img-bp ${imgBool && "hide"}`}
        alt=""
        initial="hidden" // Initial state off-screen
        animate={isAnimating ? "animate" : "stop"} // Animate when `isAnimating` is true
        variants={imgAnimationVariants}
      />
      <motion.img
        initial="hidden"
        animate={imgBool ? "bounceOut" : "hidden"} // Animate when `imgBool` is true
        variants={imgAnimationVariants2}
        src="/svgs/blueprint_lg.svg"
        className={`img-bpp ${imgBool && "reveal"}`}
        alt=""
      />
    </div>
  );
};

export default HomeContentTell;

import React from "react";
import "../../css/about/about-overview.css";

const AboutOverview = () => {
  return (
    <div className="about-overview">
      <div className="content">
        <h1>OVERVIEW</h1>
        <p>
          At Blueprint, we are dedicated to transforming innovative ideas into
          user-centric digital solutions. Founded with a passion for speed,
          innovation, and precision, our company thrives on delivering
          exceptional design and development services to startups, SMEs, and
          corporations. Our journey began with the mission to create impactful
          user experiences through cutting-edge design and development
          practices.
          <br /> <br /> What sets us apart? It's our commitment to putting the
          user first. We understand the importance of aligning design with
          function, crafting solutions that are not only beautiful but intuitive
          and efficient. Our team of experts prioritizes collaboration,
          transparency, and delivering top-quality work that meets tight
          deadlines without compromising excellence.
        </p>
      </div>
    </div>
  );
};

export default AboutOverview;

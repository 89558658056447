import React from "react";
import "../../css/work/project.content.css";
import { Link } from "react-router-dom";

const Immunocore = ({ setIsLive }) => {
  return (
    <div className="project-content">
      {/* {title} */}
      <div className="screen-landing">
        <div className="screen-landing-title">Immunocore</div>
        <div className="section-container">
          <div className="service-work">
            <p>ROLE/SERVICE</p>
            <h2>Development</h2>
          </div>
          <div className="location-work">
            <p>LOCATION</p>
            <h2>United States</h2>
          </div>
        </div>
      </div>
      <Link
        to="https://www.immunocore.us/"
        target="_blank"
        className="laptop-image-container"
        onMouseEnter={() => setIsLive(true)}
        onMouseLeave={() => setIsLive(false)}
      >
        <video autoPlay loop muted playsInline>
          <source src={`/videos/immunocore_desktop.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Link>
      <div className="phone-video-container">
        <video autoPlay loop muted playsInline>
          <source src={`/videos/immunocore_phone_video.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Immunocore;

import React, { useEffect, useState } from "react";
import BtnMagnetic from "./ButtonMagnetic";
import ProceedBtn from "./ProceedBtn";
import { motion } from "framer-motion";
// import SlideInNotifications from "./Notification";

const HomeContentOrg = ({
  handleNext,
  setIndividualDetails,
  individualDetails,
}) => {
  const [show, setShow] = useState(false);
  const [shrink, setShrink] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const { name } = individualDetails;
  const [shouldShake, setShouldShake] = useState(false);
  const [shakeKey, setShakeKey] = useState(0);
  const [shouldShakeSelect, setShouldShakeSelect] = useState(false);

  useEffect(() => {
    if (shouldShakeSelect) {
      setTimeout(() => {
        setShouldShakeSelect(false);
      }, 2000);
    }
  }, [shouldShakeSelect]);

  const shakeAnimation = {
    x: [0, -10, 10, -10, 10, 0], // Shake left and right
    transition: {
      duration: 0.3, // Duration of one shake cycle
      repeat: 5, // Shake 10 times (since the initial cycle counts as 1)
      repeatType: "loop",
    },
  };

  const onSubmit = (e) => {
    setShouldShake(false);
    setShouldShakeSelect(false);

    e.preventDefault();
    if (!name) {
      // Restart shaking by changing the `shakeKey`
      setShakeKey((prev) => prev + 1);
      setShouldShake(true); // Trigger the shake animation
    } else if (!selectedOption) {
      setShouldShakeSelect(true);
    } else {
      setShouldShake(false); // Stop shaking if the name is filled
      handleNext();
      // Additional form submission logic here
    }
  };

  const options = ["Fintech", "Web3", "Real Estate"]; // The list of options

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setIndividualDetails({
      ...individualDetails,
      organisation: event.target.value,
    });
  };

  const handleLabelClick = (option) => {
    setSelectedOption(option);
    setShrink(true);
  };

  return (
    <div className="home-content home-b home-name home-org">
      <div className="home-inner">
        <h4 className="h4-org">
          What’s the name of the organisation <br /> you are representing?
        </h4>
        <h4 className="org">
          What’s the name of the organisation you are representing?
        </h4>

        <div className="form-container">
          <motion.input
            key={shakeKey}
            type="text"
            placeholder="Enter organisation name"
            required
            value={individualDetails.name}
            onChange={(e) => {
              setIndividualDetails({
                ...individualDetails,
                name: e.target.value,
              });
              setShouldShake(false);
            }}
            style={{ border: shouldShake && "1px solid #9d0000" }}
            animate={shouldShake ? shakeAnimation : {}}
          />
          <div
            className={`select-container ${!show && "extend"} ${
              shouldShakeSelect && "shake-animation"
            } ${shrink && "shrink"}`}
            style={{ border: shouldShakeSelect && "1px solid #9d0000" }}
          >
            <div
              className="selected-content"
              onClick={() => {
                setShrink(false);

                if (shrink) {
                  setShow(true);
                } else if (!shrink && selectedOption) {
                  setShow(true);
                  setShrink(true);
                } else {
                  setShow(!show);
                }

                // if (selectedOption) {
                //   setShrink(true);
                //   setShow(true);
                // } else {
                //   setShow(!show);
                // }
              }}
            >
              <div className="selected-text">
                What industry does your organization belong to?
              </div>
              <div className="icon-container">
                <img src="/svgs/angle-down.svg" alt="icon" />
              </div>
            </div>
            {shrink && (
              <div className="selected-org">
                <div className="ctn">{selectedOption}</div>
                <img src="/svgs/checkDouble.svg" alt="" />
              </div>
            )}

            <div className="organisations">
              {options.map((option) => (
                <label
                  key={option}
                  onClick={() => handleLabelClick(option)}
                  className={`option ${
                    selectedOption === option ? "checked" : ""
                  }`}
                >
                  <div className="label">{option}</div>
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOption === option}
                    onChange={handleOptionChange}
                  />
                </label>
              ))}
            </div>
          </div>

          <BtnMagnetic>
            <ProceedBtn text="Proceed" handleNext={onSubmit} />
          </BtnMagnetic>
        </div>
      </div>
    </div>
  );
};

export default HomeContentOrg;

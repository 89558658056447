import React from "react";
import "../../css/about/about-mobile-footer.css";
import { Link } from "react-router-dom";

const AboutMobileFooter = ({ setIsHomeLink }) => {
  return (
    <div className="about-mobile-footer">
      <div className="content">
        <p>
          Your Vision, Our Blueprint for Success.
          <br />
          <br />
          At Blueprint, we don’t just design products; we craft experiences that
          resonate. Every project we undertake is an opportunity to turn your
          unique vision into a reality that stands out. <br /> <br />
          Ready to see your ideas come to life? Let’s build your blueprint for
          success together.
        </p>
        <Link
          to="/"
          onClick={() => setIsHomeLink(true)}
          className="get-started"
        >
          Get <br /> Started
        </Link>
      </div>
      <div className="mobile-footer">
        <Link
          to="/"
          onClick={() => setIsHomeLink(true)}
          className="mobile-logo"
        >
          <img src="/svgs/blueprint_tiny.svg" alt="" />
          <h2>BluePrint</h2>
        </Link>
        <div className="contact-details">
          <p>CONTACT DETAILS</p>
          <a href="mailto:help@blueprint.com">help@blueprint.com</a>
          <a href="tel:+2349060100257">+234 906 010 0257</a>
          <a href="tel:+2348035915522">+234 803 591 5522</a>
        </div>
        <div className="buisness-details">
          <p>BUSINESS DETAILS</p>
          <div className="address">
            Location: Ibadan, Oyo State, Nigeria & Near you
          </div>
        </div>
        <div className="mobile-links">
          <Link to="/projects">Projects</Link>
          <Link to="/about-us">About Us</Link>
          <Link to="/" onClick={() => setIsHomeLink(true)}>
            Get Started
          </Link>
          <Link to="/contact-us">Contact Us</Link>
        </div>
        <div className="socials">
          <p>Follow Us</p>
          <div className="social-icons">
            <img src="/svgs/linkedin_last_black.svg" alt="" />
            <img src="/svgs/instagram.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMobileFooter;

import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import "../css/screen-animation.css";
import CustomCursor from "./CustomCursor";

const ScreenLoadAnimation = ({ screenLoadBool, setScreenLoadBool }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Set initial mobile state based on screen width
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 575); // Adjust threshold as per mobile device width (e.g. 768px)
    };

    // Add event listener for screen resizing
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile(); // Initial check when component mounts

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  const containerVariants = {
    initial: {
      y: "-100vh", // Start above the viewport
      scale: 1,
      opacity: 1,
    },
    bounce: {
      y: ["0vh", "80vh", "40svh"], // Bounce to the bottom, then to the center
      transition: {
        duration: 1.5,
        ease: "easeInOut",
        times: [0, 0.5, 1], // Timing for the keyframes
      },
    },
    scaleUp: {
      scale: [1, 10],
      opacity: 0, // Scale up to fill the entire screen
      transition: {
        duration: 0.7,
        ease: "easeInOut",
      },
    },
  };

  const newContainerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "backInOut",
        delay: 0.3,
      },
    },
  };

  const handleAnimationComplete = () => {
    setIsVisible(false);
    if (isMobile) {
      setScreenLoadBool(false);
    }
  };

  return (
    <div className="screen-animation-container">
      <AnimatePresence>
        {isVisible && (
          <motion.div
            className="bulleyes absolute top-0 left-[42%] transform -translate-x-1/2 flex items-center justify-center"
            variants={containerVariants}
            // transition={{
            //   ease: "backInOut",
            // }}
            initial="initial"
            animate="bounce"
            exit="scaleUp"
            onAnimationComplete={handleAnimationComplete}
            style={{ overflow: "hidden" }}
          >
            <img src="/svgs/bulleyes.svg" alt="" />
          </motion.div>
        )}
      </AnimatePresence>

      {!isVisible && (
        <motion.div
          className="scaling w-full h-screen flex items-center justify-center"
          variants={newContainerVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <img src="/svgs/Exclude.svg" alt="" className="icon-onboard" />
          <img
            src="/svgs/Exclude-blueprint.svg"
            alt=""
            className="icon-onboard-mobile"
          />
          <CustomCursor setScreenLoadBool={setScreenLoadBool} />
        </motion.div>
      )}
    </div>
  );
};

export default ScreenLoadAnimation;

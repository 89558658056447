import React, { useEffect, useState } from "react";
import BtnMagnetic from "./ButtonMagnetic";
import ProceedBtn from "./ProceedBtn";
// import SlideInNotifications from "./Notification";
import AppPages from "./services components/AppPages";
import WebPages from "./services components/WebPages";
import BrandPg from "./services components/BrandPg";
import { IoIosCheckmark } from "react-icons/io";
import { useSelector } from "react-redux";

const HomeContentFeatures = ({
  handleNext,
  designService,
  mobile,
  web,
  mobWeb,
  setDesignService,
  setContactBool,
  allSelectedFeatures,
  setAllSelectedFeatures,
}) => {
  const [desDevFeatures, setDesDevFeatures] = useState([
    {
      title: "Database for Storage",
      selected: false,
      category: "Development",
    },
    {
      title: "Photography",
      selected: false,
      category: "Design",
    },
    {
      title: "Animation",
      selected: false,
      category: "Design/Development",
    },
    {
      title: "API Integration",
      selected: false,
      category: "Development",
    },
    {
      title: "Domain Name",
      selected: false,
      category: "Development",
    },
    {
      title: "Hosting",
      selected: false,
      category: "Development",
    },
    {
      title: "Ecommerce Integration",
      selected: false,
      category: "Development",
    },
    {
      title: "CopyWriting",
      selected: false,
      category: "Design",
    },
    {
      title: "Technical Support",
      selected: false,
      category: "Design/Development",
    },
    {
      title: "Booking System Integration",
      selected: false,
      category: "Development",
    },
  ]);

  const [desFeatures, setDesFeatures] = useState([
    {
      title: "Photography",
      selected: false,
      category: "Design",
    },
    {
      title: "Animation",
      selected: false,
      category: "Design",
    },

    {
      title: "CopyWriting",
      selected: false,
      category: "Design",
    },
    {
      title: "Technical Support",
      selected: false,
      category: "Design",
    },
    {
      title: "Booking System Integration",
      selected: false,
      category: "Development",
    },
  ]);

  const [devFeatures, setDevFeatures] = useState([
    {
      title: "Database for Storage",
      selected: false,
      category: "Development",
    },
    {
      title: "Animation",
      selected: false,
      category: "Development",
    },
    {
      title: "API Integration",
      selected: false,
      category: "Development",
    },
    {
      title: "Domain Name",
      selected: false,
      category: "Development",
    },
    {
      title: "Hosting",
      selected: false,
      category: "Development",
    },
    {
      title: "Ecommerce Integration",
      selected: false,
      category: "Development",
    },
    {
      title: "Technical Support",
      selected: false,
      category: "Development",
    },
    {
      title: "Booking System Integration",
      selected: false,
      category: "Development",
    },
  ]);

  const [features, setFeatures] = useState([]);
  const [pages, setPages] = useState("");
  const [appPages, setAppPages] = useState("");
  const [brandStatus, setBrandStatus] = useState("");
  const isFilled = useSelector((state) => state.isFilledState.isFilled);
  const isFilled2 = useSelector((state) => state.isFilledState.isFilled2);
  const [shouldShake, setShouldShake] = useState(false);

  useEffect(() => {
    if (shouldShake) {
      setTimeout(() => {
        setShouldShake(false);
      }, 2000);
    }
  }, [shouldShake]);

  useEffect(() => {
    if (isFilled && isFilled2) {
      setDesignService("Des-Dev");
    } else if (isFilled) {
      setDesignService("Design");
    } else {
      setDesignService("Development");
    }
  }, [isFilled, isFilled2]);

  useEffect(() => {
    if (designService === "Des-Dev") {
      setFeatures(desDevFeatures);
    } else if (designService === "Development") {
      setFeatures(devFeatures);
    } else {
      setFeatures(desFeatures);
    }
  }, [designService, desDevFeatures, devFeatures, desFeatures]);

  const toggleFeature = (index) => {
    if (designService === "Des-Dev") {
      const updatedFeatures = desDevFeatures.map((feature, i) => {
        if (i === index) {
          return { ...feature, selected: !feature.selected }; // Toggle selected value
        }
        return feature;
      });
      setDesDevFeatures(updatedFeatures);
    } else if (designService === "Design") {
      const updatedFeatures = desFeatures.map((feature, i) => {
        if (i === index) {
          return { ...feature, selected: !feature.selected }; // Toggle selected value
        }
        return feature;
      });
      setDesFeatures(updatedFeatures);
    } else if (designService === "Development") {
      const updatedFeatures = devFeatures.map((feature, i) => {
        if (i === index) {
          return { ...feature, selected: !feature.selected }; // Toggle selected value
        }
        return feature;
      });
      setDevFeatures(updatedFeatures);
    } else {
      return;
    }
  };

  const getSelectedFeatures = () => {
    let selectedFeatures = [];
    if (designService === "Des-Dev") {
      selectedFeatures = [
        { title: pages },
        { title: appPages },
        { title: "Branding - " + brandStatus },
        ...features.filter((feature) => feature.selected),
      ];
    } else if (designService === "Design") {
      selectedFeatures = [
        { title: pages },
        { title: appPages },
        { title: "Branding - " + brandStatus },
        ...features.filter((feature) => feature.selected),
      ];
    } else if (designService === "Development") {
      selectedFeatures = [
        { title: pages },
        { title: appPages },
        ...features.filter((feature) => feature.selected),
      ];
    } else {
      return;
    }
    if (!brandStatus) {
      console.log(
        "no brand status",
        selectedFeatures.filter((feature) => feature.title !== "")
      );
      setAllSelectedFeatures(
        selectedFeatures.filter(
          (feature) => feature.title && feature.title !== "Branding - "
        )
      );
      if (
        selectedFeatures.filter((feature) => feature.title !== "").length > 0
      ) {
        let selectBool = false;
        selectedFeatures.forEach((feature) => {
          if (feature.title === "Branding - ") {
            selectBool = false;
          } else {
            selectBool = true;
          }
        });
        return selectBool;
      } else {
        return false;
      }
    } else {
      setAllSelectedFeatures(
        selectedFeatures.filter((feature) => feature.title)
      );
      if (
        selectedFeatures.filter((feature) => feature.title !== "").length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div className="home-content home-b home-name home-org home-features">
      <div className="home-inner">
        <h4 className="feature-h4">
          Choose your BluePrint {designService} <br />
          Services
        </h4>
        <h4 className="feature-h4-mobile">
          Choose your BluePrint {designService} Services
        </h4>
        {/* <GrabMagnetic>
          <Button_A color={color} handleNext={handleNext} />
        </GrabMagnetic> */}
        {/* <div className="buttons-container">
          <BtnMagnetic>
            <ActionBtn text="I’m an individual" handleNext={handleNext} />
          </BtnMagnetic>
          <BtnMagnetic>
            <ActionBtn
              text="I’m with an Organization"
              handleNext={handleNext}
            />
          </BtnMagnetic>
        </div> */}
        {/* <SlideInNotifications /> */}

        <div className="form-container">
          <div className={`container  ${shouldShake && "shake-animation"}`}>
            <div className="service-container">
              {(mobile || mobWeb) && <AppPages setPages={setAppPages} />}
              {(web || mobWeb) && <WebPages setPages={setPages} />}
              {designService === "Design" && (
                <BrandPg setBrandStatus={setBrandStatus} />
              )}
              {designService === "Des-Dev" && (
                <BrandPg setBrandStatus={setBrandStatus} />
              )}

              <div className="ft-container">
                {features.map((feature, index) => (
                  <div
                    className="ft"
                    key={index}
                    onClick={() => toggleFeature(index)}
                  >
                    <div className="ft-text">{feature.title}</div>
                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        checked={feature.selected}
                        readOnly
                      />
                      {feature.selected && (
                        <IoIosCheckmark className="checkmark" />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <BtnMagnetic>
            <ProceedBtn
              text="Submit BluePrint"
              handleNext={() => {
                console.log(getSelectedFeatures());

                if (!getSelectedFeatures()) {
                  setShouldShake(true);
                  setContactBool(false);
                } else {
                  setContactBool(true);
                }
              }}
              // handleNext={() => }
            />
          </BtnMagnetic>
        </div>
      </div>
    </div>
  );
};

export default HomeContentFeatures;

import React, { useEffect, useState } from "react";
import "../css/work/work-mobile-project-page.css";
import Navbar from "../components/Navbar";
import { AnimatePresence } from "framer-motion";
import MenuMobileContainer from "../components/MenuMobileContainer";
import AboutMobileFooter from "../components/about mobile components/AboutMobileFooter";
import PageTransition from "../components/PageTransition";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

const WorkMobileProjectPage = ({ setIsHomeLink }) => {
  const [menuRepoBool, setMenuRepoBool] = useState(false);
  const [project, setProject] = useState({});
  const [nextProject, setNextProject] = useState({});

  const projects = [
    {
      title: "SafulPay",
      src: "/images/safulpay.jpg",
      color: "#eeff00",
      background: "rgba(250, 255, 188, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(231, 255, 133, 0.64)",
      link: "/work/safulpay",
      details:
        "SafulPay is a mobile app that allows users to securely pay for goods and services. It utilizes the NFC technology to read and write cards, making it easy for customers to pay.",
      category: "Branding & Design",
      year: 2024,
      location: "Sierra Leone",
      longImage: "/images/safulpay_mobile.png",
      phoneScreenshots: [
        "/images/safulpay_potrait_1.png",
        "/images/safulpay_potrait_2.png",
      ],
      desktop: "safulpay_desktop",
    },
    {
      title: "Immunocore",
      src: "/images/immunocore.png",
      color: "#00ffb3",
      background: "rgba(128, 255, 211, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 179, 0.64)",
      category: "Development",
      link: "/work/immunocore",
      details:
        "Immunocore is a commercial-stage biotechnology company that discovered, developed and commercialized the world’s first approved T cell receptor (TCR) therapy.",
      location: "United States",
      year: 2024,
      desktop: "immunocore_desktop",
      phoneVideo: "immunocore_phone_video",
      website: "https://www.immunocore.us",
    },
    {
      title: "Endo",
      src: "/images/endo.png",
      color: "#ff00ee",
      background: "rgba(255, 51, 238, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 0, 238, 0.64)",
      category: "Development",
      link: "/work/endo",
      details:
        "Endo empowers individuals and communities alike, fostering better health outcomes and a brighter future for all.",
      location: "United States",
      year: 2024,
      desktop: "endo_desktop",
      phoneVideo: "endo_phone_video",
      website: "https://www.endotherapeutics.us",
    },
    {
      title: "RequestButton",
      src: "/images/request.png",
      color: "#00ff2f",
      background: "rgba(128, 255, 79, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 47, 0.64)",
      owned: true,
      link: "/work/requestbutton",
      details:
        "Request is a smart, user-friendly feature that allows diners to quickly make requests at restaurants without waiting for staff.",
      location: "Global",
      year: 2023,
      category: "Design & Develpment",
      ownedImg: "/images/requestbutton_owned.png",
    },
    {
      title: "Trigger",
      src: "/images/trigger.png",
      color: "#ff5733",
      background: "rgba(255, 99, 51, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 53, 33, 0.64)",
      owned: true,
      link: "/work/trigger",
      details:
        "Trigger helps users to access their medical journey with ease, from E-Bill statements to appointment tracking.",
      location: "Global",
      year: 2023,
      category: "Design & Development ",
      ownedImg: "/images/trigger_owned.png",
    },
    {
      title: "Ateni",
      src: "/images/ateni.png",
      color: "#007bff",
      background: "rgba(51, 153, 255, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 123, 255, 0.64)",
      link: "/work/ateni",
      details:
        "Ateni is a premier residential construction company dedicated to turning dreams into reality.",
      location: "United Kingdom",
      year: 2023,
      category: "Branding & Design",
      longImage: "/images/ateni_mobile.png",
      desktop: "ateni_desktop",
      phoneScreenshots: [
        "/images/ateni_potrait_1.png",
        "/images/ateni_potrait_2.png",
      ],
    },
    {
      title: "Lendsqr",
      src: "/images/lendsqr.png",
      color: "#ffcc00",
      background: "rgba(255, 204, 51, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 204, 0, 0.64)",
      link: "/work/lendsqr",
      details:
        "Lendsqr's loan management software makes lending easy and profitable, helping you start, launch, and scale your loan business across multiple channels with smart, cost-effective solutions.",
      location: "Nigeria",
      year: 2023,
      category: "Development",
      desktop: "lendsqr_desktop",
      phoneVideo: "lendsqr_phone_video",
    },
    {
      title: "GetLinked",
      src: "/images/getlinked.png",
      color: "#8A2BE2",
      background: "rgba(138, 43, 226, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(75, 0, 130, 0.64)",
      link: "/work/getlinked",
      details: "GetLinked is a design, development and concept hackathon. ",
      location: "Nigeria",
      year: 2023,
      category: "Development",
      desktop: "getlinked_desktop",
      phoneVideo: "getlinked_phone_video",
    },
  ];

  const location = useLocation();
  const projectTitle = location.pathname.split("/")[2];

  useEffect(() => {
    const projectData = projects.find(
      (p) => p.title.toLowerCase() === projectTitle.toLowerCase()
    );
    setProject(projectData);

    const currentIndex = projects.findIndex(
      (p) => p.title.toLowerCase() === projectTitle.toLowerCase()
    );
    const nextIndex = currentIndex + 1;
    setNextProject(projects[nextIndex]);
  }, [projects]);

  return (
    <div className="work-mobile-project-page">
      <Helmet>
        <title>
          {`Blueprint - Work | ${project?.title} - Innovative Digital Solution`}
        </title>
      </Helmet>
      <img src="/svgs/home_mob_bg.svg" alt="" className="mobile-bg" />
      <Navbar
        setIsHomeLink={setIsHomeLink}
        workMb={true}
        currentIndex={2}
        setMenuRepoBool={setMenuRepoBool}
        contact={true}
      />
      <AnimatePresence mode="wait">
        {menuRepoBool && (
          <MenuMobileContainer
            setIsHomeLink={setIsHomeLink}
            setMenuRepoBool={setMenuRepoBool}
          />
        )}
      </AnimatePresence>
      <div className="container">
        {projectTitle !== "project-flex" ? (
          <h1 className="project-title">{project?.title}</h1>
        ) : (
          <h1 className="project-title">Project Flex</h1>
        )}

        {project?.ownedImg && (
          <div className="owned">
            Privately Owned & Under <br /> Development
          </div>
        )}
        {projectTitle !== "project-flex" ? (
          <div className="service">
            <div className="role">
              <h2>ROLE/SERVICE</h2>
              <p>{project?.category}</p>
            </div>
            <div className="location">
              <h2>LOCATION</h2>
              <p>{project?.location}</p>
            </div>
          </div>
        ) : (
          <div className="service">
            <div className="role">
              <h2>ROLE/SERVICE</h2>
              <p>Design & Development</p>
            </div>
            <div className="location">
              <h2>LOCATION</h2>
              <p>Global</p>
            </div>
          </div>
        )}

        {projectTitle === "project-flex" && (
          <div className="project-flex-mobile-list">
            <div className="project">
              <video autoPlay loop muted playsInline>
                <source src={`/videos/project_flex_2.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project">
              <video autoPlay loop muted playsInline>
                <source src={`/videos/project_flex_3.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project">
              <video autoPlay loop muted playsInline>
                <source src={`/videos/project_flex_4.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project">
              <video autoPlay loop muted playsInline>
                <source src={`/videos/project_flex_5.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project">
              <video autoPlay loop muted playsInline>
                <source src={`/videos/project_flex_6.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project">
              <video autoPlay loop muted playsInline>
                <source src={`/videos/project_flex_7.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project">
              <video autoPlay loop muted playsInline>
                <source src={`/videos/project_flex_8.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project">
              <video autoPlay loop muted playsInline>
                <source src={`/videos/project_flex_9.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project">
              <video autoPlay loop muted playsInline>
                <source src={`/videos/project_flex_10.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project">
              <video autoPlay loop muted playsInline>
                <source src={`/videos/project_flex_13.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project desktop">
              <img src="/images/project_flex_desktop_16.png" alt="" />
            </div>
            <div className="project">
              <video autoPlay loop muted playsInline className="edit">
                <source src={`/videos/project_flex_17.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project desktop">
              <video autoPlay loop muted playsInline>
                <source
                  src={`/videos/project_flex_desktop_18.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project desktop">
              <video autoPlay loop muted playsInline>
                <source
                  src={`/videos/project_flex_desktop_19.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project desktop">
              <img src="/images/project_flex_desktop_20.png" alt="" />
            </div>
            <div className="project desktop ">
              <video autoPlay loop muted playsInline className="edit">
                <source
                  src={`/videos/project_flex_desktop_21.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project">
              <video autoPlay loop muted playsInline className="edit">
                <source src={`/videos/project_flex_22.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project desktop">
              <img src="/images/project_flex_desktop_23.png" alt="" />
            </div>
            <div className="project desktop">
              <video autoPlay loop muted playsInline>
                <source
                  src={`/videos/project_flex_desktop_24.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="project desktop">
              <video autoPlay loop muted playsInline>
                <source
                  src={`/videos/project_flex_desktop_25.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        )}

        {project?.longImage && (
          // <img src={project.longImage} alt="" className="project-image" />
          <img src={project.longImage} alt="" className="project-image" />
        )}

        {project?.ownedImg && (
          <div className="owned-img-container">
            <img src={project.ownedImg} alt="" className="owned-img" />
          </div>
        )}
        {project?.desktop && (
          <div className="laptop-image-container">
            <video autoPlay loop muted playsInline>
              <source src={`/videos/${project.desktop}.mp4`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        {project?.phoneVideo && (
          <div className="phone-video-container">
            <video autoPlay loop muted playsInline>
              <source
                src={`/videos/${project.phoneVideo}.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        {project?.website && (
          <Link to={project.website} target="_blank" className="visit">
            Visit <br /> Website
          </Link>
        )}
        {project?.phoneScreenshots && (
          <div className="phones">
            {project.phoneScreenshots?.map((screenshot, index) => (
              <img
                key={index}
                src={screenshot}
                alt=""
                className="phone-screenshot"
              />
            ))}
          </div>
        )}
      </div>
      {projectTitle !== "project-flex" && (
        <div className="case-container">
          {nextProject ? (
            <>
              <div className="content">
                <p>Next case</p>
                <h2>{nextProject.title}</h2>
                <Link to={nextProject.link} className="case">
                  <h1>{nextProject.title}</h1>
                  <h3>{nextProject.location}</h3>
                  <div className="ctn">{nextProject.details}</div>
                  <img src="/images/grudge.png" alt="" />
                </Link>
              </div>
              <Link to="/projects" className="work">
                All <br />
                Projects
              </Link>
            </>
          ) : (
            <Link to="/projects/project-flex" className="view">
              <p>View Project Flex</p>
              <img src="/svgs/flex-arrow-short.svg" alt="" />
            </Link>
          )}
        </div>
      )}
      <AboutMobileFooter setIsHomeLink={setIsHomeLink} />
      {projectTitle !== "project-flex" ? (
        <PageTransition pageTitle={project?.title} />
      ) : (
        <PageTransition pageTitle={"Project Flex"} />
      )}
    </div>
  );
};
export default WorkMobileProjectPage;

import { useScroll } from "framer-motion";
import { useEffect, useRef } from "react";
import CardsMobile from "./CardsMobile";

const WorksMobile = () => {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });

  const projects = [
    {
      title: "SafulPay",
      src: "/images/safulpay.jpg",
      color: "#eeff00",
      background: "rgba(41, 47, 69, 0.80)",
      boxShadow: "0px 4px 50px 0px rgba(231, 255, 133, 0.64)",
      link: "/work/safulpay",
      details:
        "SafulPay is a mobile app that allows users to securely pay for goods and services. It utilizes the NFC technology to read and write cards, making it easy for customers to pay.",
      category: "Branding & Design",
      year: 2024,
      location: "Sierra Leone",
      longImage: "/images/safulpay_longimage.png",
      phoneScreenshots: [
        "/images/safulpay_potrait_1.png",
        "/images/safulpay_potrait_2.png",
      ],
      desktop: "safulpay_desktop",
    },
    {
      title: "Immuncore",
      src: "/images/immunocore.png",
      color: "#00ffb3",
      background: "#452929CC",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 179, 0.64)",
      category: "Development",
      link: "/work/immunocore",
      details:
        "Immunocore is a commercial-stage biotechnology company that discovered, developed and commercialized the world’s first approved T cell receptor (TCR) therapy.",
      location: "United States",
      year: 2024,
      desktop: "immunocore_desktop",
      phoneVideo: "immunocore_phone_video",
    },
    {
      title: "Endo",
      src: "/images/endo.png",
      color: "#ff00ee",
      background: "#453D29CC",
      boxShadow: "0px 4px 50px 0px rgba(255, 0, 238, 0.64)",
      category: "Development",
      link: "/work/endo",
      details:
        "Endo empowers individuals and communities alike, fostering better health outcomes and a brighter future for all.",
      location: "United States",
      year: 2024,
      desktop: "endo_desktop",
      phoneVideo: "endo_phone_video",
    },
    {
      title: "RequestButton",
      src: "/images/request.png",
      color: "#00ff2f",
      background: "#344529CC",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 47, 0.64)",
      owned: true,
      link: "/work/requestbutton",
      details:
        "Request is a smart, user-friendly feature that allows diners to quickly make requests at restaurants without waiting for staff.",
      location: "Global",
      year: 2023,
      category: "Design & Develpment",
      ownedImg: "/images/requestbutton_owned.png",
    },
    {
      title: "Trigger",
      src: "/images/trigger.png",
      color: "#ff5733",
      background: "#294245CC",
      boxShadow: "0px 4px 50px 0px rgba(255, 53, 33, 0.64)",
      owned: true,
      link: "/work/trigger",
      details:
        "Trigger helps users to access their medical journey with ease, from E-Bill statements to appointment tracking.",
      location: "Global",
      year: 2023,
      category: "Design",
      ownedImg: "/images/trigger_owned.png",
    },
    {
      title: "Ateni",
      src: "/images/ateni.png",
      color: "#007bff",
      background: "#322945CC",
      boxShadow: "0px 4px 50px 0px rgba(0, 123, 255, 0.64)",
      link: "/work/ateni",
      details:
        "Ateni is a premier residential construction company dedicated to turning dreams into reality.",
      location: "United Kingdom",
      year: 2023,
      category: "Branding & Design",
      longImage: "/images/ateni_longimage.png",
      desktop: "ateni_desktop",
      phoneScreenshots: [
        "/images/ateni_potrait_1.png",
        "/images/ateni_potrait_2.png",
      ],
    },
    {
      title: "Lendsqr",
      src: "/images/lendsqr.png",
      color: "#ffcc00",
      background: "#384529cc",
      boxShadow: "0px 4px 50px 0px rgba(255, 204, 0, 0.64)",
      link: "/work/lendsqr",
      details:
        "Lendsqr's loan management software makes lending easy and profitable, helping you start, launch, and scale your loan business across multiple channels with smart, cost-effective solutions.",
      location: "Nigeria",
      year: 2023,
      category: "Development",
      desktop: "lendsqr_desktop",
      phoneVideo: "lendsqr_phone_video",
    },
    {
      title: "GetLinked",
      src: "/images/getlinked.png",
      color: "#8A2BE2",
      background: "#442945cc",
      boxShadow: "0px 4px 50px 0px rgba(75, 0, 130, 0.64)",
      link: "/work/getlinked",
      details: "GetLinked is a design, development and concept hackathon. ",
      location: "Nigeria",
      year: 2023,
      category: "Development",
    },
  ];

  return (
    <div ref={container} className="work-mobile-container">
      {projects.map((project, i) => {
        const targetScale = 1 - (projects.length - i) * 0.06;
        return (
          <CardsMobile
            key={`p_${i}`}
            i={i}
            {...project}
            progress={scrollYProgress}
            range={[i * 0.1, 1]}
            targetScale={targetScale}
          />
        );
      })}
    </div>
  );
};

export default WorksMobile;

import React, { useState } from "react";

const AppPages = ({ setPages }) => {
  const [show, setShow] = useState(false);
  const [shrink, setShrink] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const options = [
    "less than 5 App Pages",
    "6 - 15 App Pages",
    "15 - 30 App Pages",
    "31 above App Pages",
  ]; // The list of options

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setPages(event.target.value);
  };

  const handleLabelClick = (option) => {
    setSelectedOption(option);
    setPages(option);
    setShrink(true);
  };

  return (
    <div
      className={`select-container ${!show && "extend"} ${shrink && "shrink"}`}
    >
      <div
        className="selected-content"
        onClick={() => {
          setShrink(false);

          if (shrink) {
            setShow(true);
          } else if (!shrink && selectedOption) {
            setShow(true);
            setShrink(true);
          } else {
            setShow(!show);
          }

          // if (selectedOption) {
          //   setShrink(true);
          //   setShow(true);
          // } else {
          //   setShow(!show);
          // }
        }}
      >
        <div className="selected-text">Select number of App Pages</div>
        <div className="icon-container">
          <img src="/svgs/angle-down.svg" alt="icon" />
        </div>
      </div>
      {shrink && (
        <div className="selected-org">
          <div className="ctn">{selectedOption}</div>
          <img src="/svgs/checkDouble.svg" alt="" />
        </div>
      )}

      <div className="organisations">
        {options.map((option) => (
          <label
            key={option}
            onClick={() => handleLabelClick(option)}
            className={`option ${selectedOption === option ? "checked" : ""}`}
          >
            <div className="label">{option}</div>
            <input
              type="radio"
              value={option}
              checked={selectedOption === option}
              onChange={handleOptionChange}
            />
          </label>
        ))}
      </div>
    </div>
  );
};

export default AppPages;

import React, { useState } from "react";
import BtnMagnetic from "./ButtonMagnetic";
import ProceedBtn from "./ProceedBtn";
import { motion } from "framer-motion";
// import SlideInNotifications from "./Notification";

const HomeContentName = ({
  handleNext,
  setIndividualDetails,
  individualDetails,
}) => {
  const { name } = individualDetails;
  const [shouldShake, setShouldShake] = useState(false);
  const [shakeKey, setShakeKey] = useState(0); // This helps to restart the animation

  const shakeAnimation = {
    x: [0, -10, 10, -10, 10, 0], // Shake left and right
    transition: {
      duration: 0.3, // Duration of one shake cycle
      repeat: 5, // Shake 10 times (since the initial cycle counts as 1)
      repeatType: "loop",
    },
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      // Restart shaking by changing the `shakeKey`
      setShakeKey((prev) => prev + 1);
      setShouldShake(true); // Trigger the shake animation
    } else {
      setShouldShake(false); // Stop shaking if the name is filled
      handleNext();
      // Additional form submission logic here
    }
  };

  return (
    <div className="home-content home-b home-name">
      <div className="home-inner">
        <h4>What is your name?</h4>
        {/* <GrabMagnetic>
          <Button_A color={color} handleNext={handleNext} />
        </GrabMagnetic> */}
        {/* <div className="buttons-container">
          <BtnMagnetic>
            <ActionBtn text="I’m an individual" handleNext={handleNext} />
          </BtnMagnetic>
          <BtnMagnetic>
            <ActionBtn
              text="I’m with an Organization"
              handleNext={handleNext}
            />
          </BtnMagnetic>
        </div> */}
        {/* <SlideInNotifications /> */}

        <div className="form-container">
          <motion.input
            key={shakeKey} // Use key to restart the animation on every submit
            type="text"
            placeholder="Enter your Name"
            value={individualDetails.name}
            onChange={(e) => {
              setIndividualDetails({
                ...individualDetails,
                name: e.target.value,
              });
              setShouldShake(false);
            }}
            style={{ border: shouldShake && "1px solid #9d0000" }}
            required
            animate={shouldShake ? shakeAnimation : {}} // Apply the shake animation when `shouldShake` is true
          />
          <BtnMagnetic>
            <ProceedBtn text="Proceed" handleNext={onSubmit} />
          </BtnMagnetic>
        </div>
      </div>
    </div>
  );
};

export default HomeContentName;

import { useScroll, useTransform, motion } from "framer-motion";
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

const AboutMobilePortofolio = () => {
  const projects = [
    {
      title: "SafulPay",
      src: "/images/safulpay.jpg",
      color: "#eeff00",
      background: "#FAFFBCCC",
      boxShadow: "0px 4px 50px 0px rgba(231, 255, 133, 0.64)",
      link: "/work/safulpay",
      details:
        "SafulPay is a mobile app that allows users to securely pay for goods and services. It utilizes the NFC technology to read and write cards, making it easy for customers to pay.",
      category: "Branding & Design",
      year: 2024,
      location: "Sierra Leone",
      longImage: "/images/safulpay_longimage.png",
      phoneScreenshots: [
        "/images/safulpay_potrait_1.png",
        "/images/safulpay_potrait_2.png",
      ],
      desktop: "safulpay_desktop",
    },
    {
      title: "Immunocore",
      src: "/images/immunocore.png",
      color: "#00ffb3",
      background: "rgba(128, 255, 211, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 179, 0.64)",
      category: "Development",
      link: "/work/immunocore",
      details:
        "Immunocore is a commercial-stage biotechnology company that discovered, developed and commercialized the world’s first approved T cell receptor (TCR) therapy.",
      location: "United States",
      year: 2024,
      desktop: "immunocore_desktop",
      phoneVideo: "immunocore_phone_video",
    },
    {
      title: "Endo",
      src: "/images/endo.png",
      color: "#ff00ee",
      background: "rgba(255, 51, 238, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 0, 238, 0.64)",
      category: "Development",
      link: "/work/endo",
      details:
        "Endo empowers individuals and communities alike, fostering better health outcomes and a brighter future for all.",
      location: "United States",
      year: 2024,
      desktop: "endo_desktop",
      phoneVideo: "endo_phone_video",
    },
    {
      title: "RequestButton",
      src: "/images/request.png",
      color: "#00ff2f",
      background: "rgba(128, 255, 79, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 47, 0.64)",
      owned: true,
      link: "/work/requestbutton",
      details:
        "Request is a smart, user-friendly feature that allows diners to quickly make requests at restaurants without waiting for staff.",
      location: "Global",
      year: 2023,
      category: "Design & Develpment",
      ownedImg: "/images/requestbutton_owned.png",
    },
    {
      title: "Trigger",
      src: "/images/trigger.png",
      color: "#ff5733",
      background: "rgba(255, 99, 51, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 53, 33, 0.64)",
      owned: true,
      link: "/work/trigger",
      details:
        "Trigger helps users to access their medical journey with ease, from E-Bill statements to appointment tracking.",
      location: "Global",
      year: 2023,
      category: "Design",
      ownedImg: "/images/trigger_owned.png",
    },
  ];

  const navigate = useNavigate();

  return (
    <div className="about-mobile-portofolio">
      <div className=" relative ">
        {/* <StickImage /> */}
        <div className="intro">
          <h1>PORTFOLIOS</h1>
          <h2>Our Blueprint for Success: Projects That Speak for Themselves</h2>
          <p>
            At Blueprint, we believe that our work should speak louder than
            words. Below, you’ll find a curated selection of our projects across
            various industries. Each project is a testament to our commitment to
            delivering exceptional design and development solutions tailored to
            our clients’ unique needs.
          </p>
        </div>
        <div className="portfolios">
          {projects.map((project, index) => (
            <div onClick={() => navigate(project.link)} className="portfolio">
              <h2>{project.title}</h2>
              <p>{project.category}</p>
              <div className="img-container">
                <img src={project.src} className="img" alt="" />
                <div
                  className="proceed-icon-wrapper"
                  style={{
                    background: project.background,
                    boxShadow: project.boxShadow,
                  }}
                >
                  <img src="/svgs/angle-right.svg" alt="icon" />
                </div>
              </div>
              <div className="content">{project.details}</div>
            </div>
          ))}
          <Link to="/projects" className="all-portfolio">
            All <br /> Projects
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutMobilePortofolio;

const StickImage = ({ imgUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start start", "end start"],
  });
  const IMG_PADDING = 12;

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        background: "pink",
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: `${IMG_PADDING}%`,
        scale,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden rounded-3xl"
    >
      {/* Content */}
    </motion.div>
  );
};

import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import BurgerMenu from "../components/BurgerMenu";
import { AnimatePresence, motion } from "framer-motion";
import MenuContainer from "../components/MenuContainer";
import StickyCursor from "../components/StickyCursor";
import "../css/work/work.css";
import GrabMagnetic from "../components/GrabMagnetic";
import WorkPortofolio from "../components/work components/WorkPortofolio";
import StickMenuContainer from "../components/about components/StickMenuContainer";
import BtnMagnetic from "../components/ButtonMagnetic";
import transition from "../transitions";
import PageTransition from "../components/PageTransition";
import WorkMobilePage from "./WorkMobilePage";
import WorkLandScapePortfolio from "../components/work components/WorkLandScapePortfolio";
import { Helmet } from "react-helmet";
import StickProceedIcon from "../components/about components/StickProceedIcon";
import { Link } from "react-router-dom";

const Work = ({ setIsHomeLink, isHomeLink }) => {
  const [menuBool, setMenuBool] = useState(false);
  const stickyElement = useRef(null);
  const [isPortofolio, setIsPortofolio] = useState(false);
  const [modal, setModal] = useState({ active: false, index: 0 });
  const [gridActive, setGridActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [screenLoadBool, setScreenLoadBool] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setScreenLoadBool(false);
    }, [300]);
  }, []);

  useEffect(() => {
    // Set initial mobile state based on screen width
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 575); // Adjust threshold as per mobile device width (e.g. 768px)
    };

    // Add event listener for screen resizing
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile(); // Initial check when component mounts

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  const projects = [
    {
      title: "SafulPay",
      src: "/images/safulpay.jpg",
      color: "#eeff00",
      background: "rgba(250, 255, 188, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(231, 255, 133, 0.64)",
      link: "/work/safulpay",
      details:
        "SafulPay is a mobile app that allows users to securely pay for goods and services. It utilizes the NFC technology to read and write cards, making it easy for customers to pay.",
      category: "Branding & Design",
      year: 2024,
      location: "Sierra Leone",
      longImage: "/images/safulpay_longimage.png",
      phoneScreenshots: [
        "/images/safulpay_potrait_1.png",
        "/images/safulpay_potrait_2.png",
      ],
      desktop: "safulpay_desktop",
    },
    {
      title: "Immunocore",
      src: "/images/immunocore.png",
      color: "#00ffb3",
      background: "rgba(128, 255, 211, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 179, 0.64)",
      category: "Development",
      link: "/work/immunocore",
      details:
        "Immunocore is a commercial-stage biotechnology company that discovered, developed and commercialized the world’s first approved T cell receptor (TCR) therapy.",
      location: "United States",
      year: 2024,
      desktop: "immunocore_desktop",
      phoneVideo: "immunocore_phone_video",
    },
    {
      title: "Endo",
      src: "/images/endo.png",
      color: "#ff00ee",
      background: "rgba(255, 51, 238, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 0, 238, 0.64)",
      category: "Development",
      link: "/work/endo",
      details:
        "Endo empowers individuals and communities alike, fostering better health outcomes and a brighter future for all.",
      location: "United States",
      year: 2024,
      desktop: "endo_desktop",
      phoneVideo: "endo_phone_video",
    },
    {
      title: "RequestButton",
      src: "/images/request.png",
      color: "#00ff2f",
      background: "rgba(128, 255, 79, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 255, 47, 0.64)",
      owned: true,
      link: "/work/requestbutton",
      details:
        "Request is a smart, user-friendly feature that allows diners to quickly make requests at restaurants without waiting for staff.",
      location: "Global",
      year: 2023,
      category: "Design & Develpment",
      ownedImg: "/images/requestbutton_owned.png",
    },
    {
      title: "Trigger",
      src: "/images/trigger.png",
      color: "#ff5733",
      background: "rgba(255, 99, 51, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 53, 33, 0.64)",
      owned: true,
      link: "/work/trigger",
      details:
        "Trigger helps users to access their medical journey with ease, from E-Bill statements to appointment tracking.",
      location: "Global",
      year: 2023,
      category: "Design",
      ownedImg: "/images/trigger_owned.png",
    },
    {
      title: "Ateni",
      src: "/images/ateni.png",
      color: "#007bff",
      background: "rgba(51, 153, 255, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(0, 123, 255, 0.64)",
      link: "/work/ateni",
      details:
        "Ateni is a premier residential construction company dedicated to turning dreams into reality.",
      location: "United Kingdom",
      year: 2023,
      category: "Branding & Design",
      longImage: "/images/ateni_longimage.png",
      desktop: "ateni_desktop",
      phoneScreenshots: [
        "/images/ateni_potrait_1.png",
        "/images/ateni_potrait_2.png",
      ],
    },
    {
      title: "Lendsqr",
      src: "/images/lendsqr.png",
      color: "#ffcc00",
      background: "rgba(255, 204, 51, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(255, 204, 0, 0.64)",
      link: "/work/lendsqr",
      details:
        "Lendsqr's loan management software makes lending easy and profitable, helping you start, launch, and scale your loan business across multiple channels with smart, cost-effective solutions.",
      location: "Nigeria",
      year: 2023,
      category: "Development",
      desktop: "lendsqr_desktop",
      phoneVideo: "lendsqr_phone_video",
    },
    {
      title: "GetLinked",
      src: "/images/getlinked.png",
      color: "#8A2BE2",
      background: "rgba(138, 43, 226, 0.8)",
      boxShadow: "0px 4px 50px 0px rgba(75, 0, 130, 0.64)",
      link: "/work/getlinked",
      details: "GetLinked is a design, development and concept hackathon. ",
      location: "Nigeria",
      year: 2023,
      category: "Development",
    },
  ];

  return (
    <>
      {!isMobile ? (
        <div className="work-section">
          <Helmet>
            <title>
              Blueprint - PROJECTS | Showcasing Our Innovative Digital Solutions
            </title>
          </Helmet>
          <PageTransition pageTitle={"projects"} />
          {!screenLoadBool && (
            <>
              <Navbar ref={stickyElement} currentIndex={2} />
              <img
                src="/svgs/home_background_black.svg"
                alt=""
                className="work-bg"
              />
              <div className="work-container">
                <motion.h1
                  initial={{ y: 0, rotate: "0deg" }} // Starting position (top)
                  animate={{
                    x: gridActive ? "-180px" : "0", // Move to the left
                    y: gridActive ? "40vh" : "0", // Move to the center vertically
                    rotate: gridActive ? "-90deg" : "0",
                    transition: { duration: 0.5 }, // Animation duration
                  }}
                >
                  PROJECTS
                </motion.h1>
                <BtnMagnetic>
                  <Link to="/projects/project-flex" className="layout-btn">
                    <div className="scope">
                      <p>View Projects Flex</p>
                    </div>
                  </Link>
                </BtnMagnetic>
              </div>
              {/* <WorkPortofolio
            modal={modal}
            setModal={setModal}
            setIsPortofolio={setIsPortofolio}
            projects={projects}
          /> */}
              {!gridActive ? (
                <WorkPortofolio
                  modal={modal}
                  setModal={setModal}
                  setIsPortofolio={setIsPortofolio}
                  projects={projects}
                />
              ) : (
                <WorkLandScapePortfolio />
              )}
              <AnimatePresence mode="wait">
                {menuBool && (
                  <MenuContainer
                    setMenuBool={setMenuBool}
                    setIsHomeLink={setIsHomeLink}
                    work={true}
                  />
                )}
              </AnimatePresence>
              <BurgerMenu
                ref={stickyElement}
                menuBool={menuBool}
                setMenuBool={setMenuBool}
                currentIndex={2}
              />
              <StickyCursor currentIndex={2} stickyElement={stickyElement} />
              <StickMenuContainer
                isPortofolio={isPortofolio}
                modal={modal}
                projects={projects}
              />
              <StickProceedIcon isPortofolio={isPortofolio} />
            </>
          )}
        </div>
      ) : (
        <WorkMobilePage
          setIsHomeLink={setIsHomeLink}
          screenLoadBool={screenLoadBool}
        />
      )}
    </>
  );
};

export default Work;

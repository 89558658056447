import React, { useEffect, useState } from "react";
import ProceedBtn from "./ProceedBtn";
import BtnMagnetic from "./ButtonMagnetic";
import { motion } from "framer-motion";

const HomeContentDevice = ({
  handleNext,
  designService,
  isFilled,
  isFilled2,
  isFilled3,
  setIsFilled,
  setIsFilled2,
  setIsFilled3,
}) => {
  const [shouldShake, setShouldShake] = useState(false);

  useEffect(() => {
    if (shouldShake) {
      setTimeout(() => {
        setShouldShake(false);
      }, 2000);
    }
  }, [shouldShake]);

  const handleFillCard = () => {
    setIsFilled(!isFilled);
    if (isFilled2 || isFilled3) {
      setIsFilled2(false);
      setIsFilled3(false);
    }
  };

  const handleFillCard2 = () => {
    setIsFilled2(!isFilled2);
    if (isFilled || isFilled3) {
      setIsFilled(false);
      setIsFilled3(false);
    }
  };

  const handleFillCard3 = () => {
    setIsFilled3(!isFilled3);
    if (isFilled2 || isFilled) {
      setIsFilled2(false);
      setIsFilled(false);
    }
  };

  return (
    <div className="home-content home-device">
      <div className="home-inner">
        <h4>
          Please select the platform for your <br />
          {designService} Project?
        </h4>

        <div className={`cards  ${shouldShake && "shake-animation"}`}>
          <div
            className={`card ${isFilled && "filled"}`}
            onClick={handleFillCard}
          >
            <motion.div
              className="bg-cd"
              initial={{ scale: 0 }} // Start width at 0%
              animate={{ scale: isFilled ? 4 : 0 }} // Animate to 100% when triggered
              transition={{
                duration: isFilled ? 1 : 0.5,
                ease: isFilled ? "circInOut" : "linear",
              }} // Animation duration and easing
            ></motion.div>
            <img src="/svgs/mobile-button.svg" alt="" />
            <p>
              Mobile <br /> Application
            </p>
          </div>
          <div
            className={`card ${isFilled2 && "filled"}`}
            onClick={handleFillCard2}
          >
            <motion.div
              className="bg-cd"
              initial={{ scale: 0 }} // Start width at 0%
              animate={{ scale: isFilled2 ? 4 : 0 }} // Animate to 100% when triggered
              transition={{
                duration: isFilled2 ? 1 : 0.5,
                ease: isFilled2 ? "circInOut" : "linear",
              }} // Animation duration and easing
            ></motion.div>
            <img src="/svgs/laptop.svg" alt="" />
            <p>
              Web <br /> Application
            </p>
          </div>
          <div
            className={`card ${isFilled3 && "filled"}`}
            onClick={handleFillCard3}
          >
            <motion.div
              className="bg-cd"
              initial={{ scale: 0 }} // Start width at 0%
              animate={{ scale: isFilled3 ? 4 : 0 }} // Animate to 100% when triggered
              transition={{
                duration: isFilled3 ? 1 : 0.5,
                ease: isFilled3 ? "circInOut" : "linear",
              }} // Animation duration and easing
            ></motion.div>
            <img src="/svgs/laptop-mobile.svg" alt="" />
            <p>
              Both Mobile & Web <br /> Application
            </p>
          </div>
        </div>

        <div
          className={`cards cards-mobile-repo ${
            shouldShake && "shake-animation"
          }`}
        >
          <div className="card-wrap">
            <div
              className={`card ${isFilled && "filled"}`}
              onClick={handleFillCard}
            >
              <motion.div
                className="bg-cd"
                initial={{ scale: 0 }} // Start width at 0%
                animate={{ scale: isFilled ? 4 : 0 }} // Animate to 100% when triggered
                transition={{
                  duration: isFilled ? 1 : 0.5,
                  ease: isFilled ? "circInOut" : "linear",
                }} // Animation duration and easing
              ></motion.div>
              <img src="/svgs/mobile-button.svg" alt="" />
              <p>
                Mobile <br /> Application
              </p>
            </div>
            <div
              className={`card ${isFilled2 && "filled"}`}
              onClick={handleFillCard2}
            >
              <motion.div
                className="bg-cd"
                initial={{ scale: 0 }} // Start width at 0%
                animate={{ scale: isFilled2 ? 4 : 0 }} // Animate to 100% when triggered
                transition={{
                  duration: isFilled2 ? 1 : 0.5,
                  ease: isFilled2 ? "circInOut" : "linear",
                }} // Animation duration and easing
              ></motion.div>
              <img src="/svgs/laptop.svg" alt="" />
              <p>
                Web <br /> Application
              </p>
            </div>
          </div>
          <div
            className={`card ${isFilled3 && "filled"}`}
            onClick={handleFillCard3}
          >
            <motion.div
              className="bg-cd"
              initial={{ scale: 0 }} // Start width at 0%
              animate={{ scale: isFilled3 ? 4 : 0 }} // Animate to 100% when triggered
              transition={{
                duration: isFilled3 ? 1 : 0.5,
                ease: isFilled3 ? "circInOut" : "linear",
              }} // Animation duration and easing
            ></motion.div>
            <img src="/svgs/laptop-mobile.svg" alt="" />
            <p>
              Both Mobile & Web <br /> Application
            </p>
          </div>
        </div>

        <BtnMagnetic>
          <ProceedBtn
            text="Start customizing my service"
            handleNext={() => {
              if (!isFilled && !isFilled2 && !isFilled3) {
                setShouldShake(true);
              } else {
                handleNext();
              }
            }}
            color="#37bb9ed3"
          />
        </BtnMagnetic>
      </div>
    </div>
  );
};

export default HomeContentDevice;

import React from "react";
import { motion } from "framer-motion";
import "../css/back-btn.css";

const BackBtn = ({ handlePrev, color = "#37bb9ed3", backExist }) => {
  return (
    <motion.div
      className="parent-btn"
      initial={{ x: "-100vw", opacity: 0 }} // Start offscreen
      animate={backExist ? { x: 0, opacity: 1 } : { x: "-100vw", opacity: 0 }} // Animate to screen when backExist is true
      transition={{
        duration: 0.8,
        ease: "backInOut",
      }}
    >
      <motion.div
        className=" back-btn"
        whileHover="hover"
        initial="initial"
        animate="animate"
        onClick={handlePrev}
      >
        <motion.div
          className="background"
          variants={{
            initial: {
              scaleY: 0,
              scaleX: 0.5,
              originY: 1,
              borderRadius: "0px 300px 300px 0px",
              backgroundColor: "#ffffff",
            },
            hover: {
              scaleY: 1,
              scaleX: 1,
              borderRadius: "0px 300px 300px 0px",
              backgroundColor: color,
              transition: {
                duration: 0.6,
                ease: "easeInOut",
                scaleX: { duration: 0.3 },
                scaleY: { delay: 0.1, duration: 0.5 },
              },
            },
            animate: {
              scaleY: 0,
              scaleX: 0.2,
              originY: 1,
              borderRadius: "50px",
              backgroundColor: "#ffffff",
              transition: { duration: 0.6, ease: "easeInOut" },
            },
          }}
        />
        <div className="text-btn">Go back</div>
      </motion.div>
    </motion.div>
  );
};

export default BackBtn;

import React from "react";
import { motion } from "framer-motion";

const ActionBtn = ({ color = "#FCEDD6", handleNext, text = "" }) => {
  return (
    <motion.div
      className="btn action-btn"
      whileHover="hover"
      initial="initial"
      animate="animate"
      onClick={handleNext}
    >
      <motion.div
        className="background"
        variants={{
          initial: {
            scaleY: 0,
            scaleX: 0.5,
            originY: 1,
            borderRadius: "50%",
            backgroundColor: "#ffffff", // Initial background color
          },
          hover: {
            scaleY: 1,
            scaleX: 1,
            borderRadius: "50px", // Rounded corners after hover
            backgroundColor: color, // Change background color based on prop
            transition: {
              duration: 0.6,
              ease: "easeInOut",
              scaleX: { duration: 0.3 }, // Horizontal stretch first
              scaleY: { delay: 0.1, duration: 0.5 }, // Then vertical stretch
            },
          },
          animate: {
            scaleY: 0,
            scaleX: 0.2,
            originY: 1,
            borderRadius: "50px",
            backgroundColor: "#ffffff", // Reset to initial background color
            transition: { duration: 0.6, ease: "easeInOut" },
          },
        }}
      />
      <div className="text">{text}</div>
      <div className="icon-container">
        <img src="/svgs/right-black.svg" alt="icon" />
      </div>
    </motion.div>
  );
};

export default ActionBtn;

import React, { useEffect, useState } from "react";
import BtnProceed from "./BtnProceed";
import GrabMagnetic from "./GrabMagnetic";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { toggleIsFilled, toggleIsFilled2 } from "../redux/isFilledSlice";

const HomeContentSercvice = ({
  handleNext,
  setDesignService,
  individualDetails,
}) => {
  const dispatch = useDispatch();
  const isFilled = useSelector((state) => state.isFilledState.isFilled);
  const isFilled2 = useSelector((state) => state.isFilledState.isFilled2);
  const [isColorChanged, setIsColorChanged] = useState(false);
  const [isColorChanged2, setIsColorChanged2] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const controls = useAnimation();

  const handleFillCard = () => {
    dispatch(toggleIsFilled());
    setDesignService("Design");
  };

  const handleFillCard2 = () => {
    dispatch(toggleIsFilled2());
    setDesignService("Development");
  };

  useEffect(() => {
    if (isFilled && isFilled2) {
      setDesignService("Des-Dev");
    }
  }, [isFilled, isFilled2]);

  useEffect(() => {
    const checkBackgroundColor = (element) => {
      if (element) {
        const styles = getComputedStyle(element);
        const backgroundColor = styles.backgroundColor;
        setIsColorChanged(backgroundColor === "rgb(44, 44, 44)");
      }
    };

    const targetElement = document.querySelector(".fusion .lb");

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "style"
        ) {
          checkBackgroundColor(mutation.target);
        }
      }
    });

    if (targetElement) {
      observer.observe(targetElement, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const checkBackgroundColor = (element) => {
      if (element) {
        const styles = getComputedStyle(element);
        const backgroundColor = styles.backgroundColor;
        setIsColorChanged2(backgroundColor === "rgb(44, 44, 44)");
      }
    };

    const targetElement = document.querySelector(".fusion .rb");

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "style"
        ) {
          checkBackgroundColor(mutation.target);
        }
      }
    });

    if (targetElement) {
      observer.observe(targetElement, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }

    return () => observer.disconnect();
  }, []);

  const startAnimation = async () => {
    if (!controls) return;

    try {
      await controls.start({
        scale: 2,
        opacity: 1,
        transition: { duration: 0.5, ease: "easeIn" },
      });
      await controls.start({
        scale: 6,
        opacity: 1,
        transition: { duration: 1.2, ease: "easeIn" },
      });
      controls.start({
        scale: [6, 2],
        opacity: 1,
        transition: {
          duration: 1,
          repeat: Infinity,
          repeatType: "loop",
          ease: "easeIn",
        },
      });
    } catch (error) {
      console.error("Animation failed to start:", error);
    }
  };

  useEffect(() => {
    if (isColorChanged && isColorChanged2) {
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
    }
  }, [isColorChanged, isColorChanged2]);

  useEffect(() => {
    let mounted = true;

    if (isAnimating && mounted) {
      startAnimation();
    } else {
      controls.stop();
      controls.start({
        scale: 1,
        opacity: 0.2,
        transition: { duration: 1.4, ease: "easeIn" },
      });
    }

    return () => {
      mounted = false;
    };
  }, [isAnimating, controls]);

  const shrinkName = (inputName) => {
    if (inputName.length > 16) {
      return inputName.slice(0, 13) + "..."; // Show first 13 characters, then ellipsis
    }
    return inputName; // Return full name if it's 16 characters or less
  };

  function capitalizeFirstLetter(word) {
    if (!word) return ""; // Handle cases where the word is empty or null
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  return (
    <div className="home-content home-service">
      <div className="home-inner">
        <h1 className="service-h1">
          Good Afternoon,{" "}
          {capitalizeFirstLetter(shrinkName(individualDetails.name))},
        </h1>
        <h1 className="service-h1-mobile">
          Good Afternoon,
          <br /> {capitalizeFirstLetter(shrinkName(individualDetails.name))}
        </h1>

        <div className="ctn-wrapper">
          <div className="content">
            <h4>What’s your Blueprint for success?</h4>
            <p className="ctn-p">
              Choose the service that aligns with your needs—whether it's
              Design, Development, or <br /> both. Simply click on the check
              button for your preferred service, then click the black <br />{" "}
              arrow to proceed.
            </p>
            <p className="ctn-mobile-p">
              Choose the service that aligns with your needs—whether it's
              Design, Development, or both. Simply click on the check button for
              your preferred service, then click the black arrow below the cards
              to proceed.
            </p>
          </div>
          <GrabMagnetic>
            <BtnProceed
              isFilled={isFilled}
              isFilled2={isFilled2}
              handleNext={handleNext}
            />
          </GrabMagnetic>
        </div>

        <div className="service-cards">
          <div
            className={`service-card ${isFilled && "filled"}`}
            onClick={handleFillCard}
          >
            <motion.div
              className="bg-card"
              initial={{ width: "0%", left: 0 }} // Start width at 0%
              animate={{ width: isFilled ? "100%" : "0%", left: 0 }} // Animate to 100% when triggered
              transition={{ duration: 2, ease: "circInOut" }} // Animation duration and easing
            ></motion.div>
            <div className="scope">
              <div className="scope-title">DESIGN</div>
            </div>

            <div className="work">
              <p>User Experinence (UX) Design</p>
              <p>User Interface (UI) Design</p>
              <p>Product Design</p>
              <p>Brand Identity Design</p>
              <p>Wireframing & Prototyping</p>
            </div>

            <img src="/svgs/checkDouble.svg" alt="" />
          </div>

          <AnimatePresence>
            <div className="fusion">
              <motion.div
                className="left"
                initial={{ x: 0 }}
                animate={{ x: isAnimating ? "-100%" : 0 }} // Slide the container to the left
                transition={{
                  duration: 1,
                  ease: "circInOut",
                  delay: isAnimating ? 0 : 1, // Adjust delay based on isAnimating state
                }}
              >
                <motion.div
                  initial={{ background: "#f1f1f1" }}
                  animate={{ background: isFilled ? "#2c2c2c" : "#f1f1f1" }}
                  transition={{ duration: 1, ease: "circInOut", delay: 1.8 }}
                  className="bar"
                ></motion.div>
                <motion.div
                  className="bar lb"
                  initial={{ background: "#f1f1f1" }}
                  animate={{ background: isFilled ? "#2c2c2c" : "#f1f1f1" }}
                  transition={{
                    duration: 1,
                    ease: "circInOut",
                    delay: !isFilled ? 1.8 : 2.5,
                  }}
                ></motion.div>
              </motion.div>
              <motion.div
                exit={{
                  opacity: 0,
                  transition: { duration: 1, ease: "circInOut" },
                }}
                animate={controls}
                className="bp"
              >
                <motion.img
                  src="/svgs/blueprint_lg.svg"
                  // src="/svgs/blueprint_md.svg"
                  alt=""
                  //   style={{
                  //     width: "100px",
                  //     height: "100px",
                  //     backgroundImage: "url(/path/to/image.jpg)",
                  //     backgroundSize: "cover",
                  //   }}
                />
                {/* {isColorChanged2 && <img />} */}
              </motion.div>
              <motion.div
                className="right"
                initial={{ x: 0 }}
                animate={{ x: isAnimating ? "100%" : 0 }} // Slide the container to the left
                transition={{
                  duration: 1,
                  ease: "circInOut",
                  delay: isAnimating ? 0 : 1, // Adjust delay based on isAnimating state
                }}
              >
                <motion.div
                  className="bar rb"
                  initial={{ background: "#f1f1f1" }}
                  animate={{ background: isFilled2 ? "#2c2c2c" : "#f1f1f1" }}
                  transition={{
                    duration: 1,
                    ease: "circInOut",
                    delay: !isFilled2 ? 1.8 : 2.5,
                  }}
                ></motion.div>
                <motion.div
                  className="bar"
                  initial={{ background: "#f1f1f1" }}
                  animate={{ background: isFilled2 ? "#2c2c2c" : "#f1f1f1" }}
                  transition={{ duration: 1, ease: "circInOut", delay: 1.8 }}
                ></motion.div>
              </motion.div>
            </div>
          </AnimatePresence>

          <div
            className={`service-card snd ${isFilled2 && "filled"}`}
            onClick={handleFillCard2}
          >
            <motion.div
              className="bg-card bg-rg"
              initial={{ width: "0%", right: 0 }}
              animate={{ width: isFilled2 ? "100%" : "0%", right: 0 }}
              transition={{ duration: 2, ease: "easeInOut" }}
            ></motion.div>
            <div className="scope">
              <div className="scope-title">DEVELOP </div>
            </div>

            <div className="work">
              <p>Web Development</p>
              <p>Custom Software Development</p>
              <p>Mobile App Development</p>
              <p>API Integration</p>
              <p>E-commerce Development</p>
            </div>

            <img src="/svgs/checkDouble.svg" alt="" />
          </div>
        </div>

        <div className="btn-mobile-proceed-container">
          {(isFilled || isFilled2) && (
            <div className="btn-mobile-proceed" onClick={handleNext}>
              <img src="/svgs/angle-right.svg" alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeContentSercvice;

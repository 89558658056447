import React from "react";
import GrabMagnetic from "../GrabMagnetic";
import BtnProceed from "../BtnProceed";

const AboutLanding = ({ scrollToEnd, individualDetails }) => {
  function capitalizeFirstLetter(word) {
    if (!word) return ""; // Handle cases where the word is empty or null
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  return (
    <div className="about-landing" style={{ paddingRight: "150px" }}>
      <div className="plan">
        <h1>FROM YOUR IDEAS</h1>
      </div>
      <div className="plan">
        <p className="welcome" style={{ fontSize: "23px" }}>
          {individualDetails.name
            ? `Welcome to Blueprint, ${capitalizeFirstLetter(
                individualDetails.name
              )}!`
            : "Welcome to Blueprint!"}{" "}
          <br /> <br /> We’re thrilled to partner with you on your journey to
          success. Here’s a closer look at what we do and why so many trust us
          with their most important projects.
        </p>
        <h1 className="slim">TO BLUEPRINTING</h1>
      </div>
      <div className="plan">
        <h1>INTO REALITY</h1>
        <div className="container">
          <p style={{ fontSize: "22px" }}>
            At Blueprint, we believe that great design is the foundation of
            every successful product.
            <br /> <br /> Our team of expert designers and developers
            collaborates closely with you to understand your vision and turn it
            into reality. Whether you’re a startup with a bold new idea or an
            SME looking to refine your existing offerings, Blueprint is here to
            help.
          </p>
          <GrabMagnetic>
            <BtnProceed
              handleNext={scrollToEnd}
              // isFilled={isFilled}
              // isFilled2={isFilled2}
              // handleNext={handleNext}
            />
          </GrabMagnetic>
        </div>
      </div>
    </div>
  );
};

export default AboutLanding;

import React, { useEffect } from "react";
import { motion, useMotionValue, useSpring } from "framer-motion";
import "../../css/work/sticky-case.css";

const StickyCase = ({ isCase }) => {
  const mouse = {
    x: useMotionValue(0),
    y: useMotionValue(0),
  };

  const cursorSize = 200;

  const smoothOptions = { damping: 20, stiffness: 300, mass: 0.5 };

  const smoothMouse = {
    x: useSpring(mouse.x, smoothOptions),
    y: useSpring(mouse.y, smoothOptions),
  };

  const manageMouseMove = (e) => {
    const { clientX, clientY } = e;
    mouse.x.set(clientX - cursorSize / 2);
    mouse.y.set(clientY - cursorSize / 2);
  };

  useEffect(() => {
    window.addEventListener("mousemove", manageMouseMove);
    return () => {
      window.removeEventListener("mousemove", manageMouseMove);
    };
  });

  return (
    <motion.div
      className={`menu-cursor ${isCase && "visible"}`}
      style={{ left: smoothMouse.x, top: smoothMouse.y }}
    >
      Next Case
    </motion.div>
  );
};

export default StickyCase;

import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import "../css/page-transition.css";

const PageTransition = ({ pageTitle }) => {
  const loader = useRef(null);
  const path = useRef(null);
  const animationFrame = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);

  useEffect(() => {
    setIsVisible(false);
    setTimeout(() => {
      setIsVisible(true);
    }, 2000);
  }, []);

  useEffect(() => {
    // Debounce the resize event
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 575);
    };

    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(updateIsMobile, 150);
    };

    let resizeTimeout = null;
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const initialCurve = isMobile ? 100 : 110;
  const duration = 800;
  let start;

  useEffect(() => {
    if (loader.current && path.current) {
      setPath(initialCurve); // Ensure the path and loader exist before setting path
      setTimeout(() => {
        animationFrame.current = requestAnimationFrame(animate);
      }, 1000);
    }

    // Clean up animation frame when the component unmounts
    return () => {
      if (animationFrame.current) {
        cancelAnimationFrame(animationFrame.current);
      }
    };
  }, [isMobile]); // Rerun animation when `isMobile` changes

  const animate = (timestamp) => {
    if (!loader.current || !path.current) return;

    if (!start) {
      start = timestamp;
    }

    const elapsed = timestamp - start;
    const newCurve = easeOutQuad(elapsed, initialCurve, -200, duration);
    setPath(newCurve);

    loader.current.style.top =
      easeOutQuad(elapsed, 0, -loaderHeight(), duration) + "px";

    if (elapsed < duration) {
      animationFrame.current = requestAnimationFrame(animate);
    }
  };

  const easeOutQuad = (time, start, end, duration) => {
    time /= duration;
    return -end * time * (time - 2) + start;
  };

  const loaderHeight = () => {
    if (loader.current) {
      return loader.current.getBoundingClientRect().height;
    }
    return 0;
  };

  const setPath = (curve) => {
    if (path.current && loader.current) {
      const width = window.innerWidth;
      const height = loaderHeight();
      path.current.setAttributeNS(
        null,
        "d",
        `M0 0
         L${width} 0
         L${width} ${height}
         Q${width / 2} ${height - curve} 0 ${height}
         L0 0`
      );
    }
  };

  return (
    <div ref={loader} className={`loader ${isVisible && "visible"}`}>
      <motion.div
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2, delay: 0 }}
        className="title"
      >
        <div className="dots"></div>
        <h1>{pageTitle}</h1>
      </motion.div>
      <svg>
        <path ref={path}></path>
      </svg>
    </div>
  );
};

export default PageTransition;
